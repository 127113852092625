import React, {useState, useEffect, useRef, createContext} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";

export const WeightClassContext = createContext({});

const useStyles = createUseStyles({
    root: {
        flex: "1",
        display: "flex",
        alignItems: "stretch"
    }
});

const WeightClassSelect = props => {
    const {
        children,
        selected,
        colors,
        onChange
    } = props;
    const classes = useStyles();
    const [selectedOption, selectOption] = useState(selected);
    const ref = useRef(false);

    useEffect(() => {
        if (ref.current) {
            if (onChange) onChange(selectedOption);
        } else {
            ref.current = true;
        }
    },[selectedOption])

    return (
        <WeightClassContext.Provider value={{selectedOption, selectOption, colors}}>
            <div className={classes.root}>
                {children}
            </div>
        </WeightClassContext.Provider>
    );
}

WeightClassSelect.defaultProps = {
    colors: {
        default: "red",
        selected: "blue"
    },
    onChange: () => {}
}

WeightClassSelect.propTypes = {
    children: PropTypes.node.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.string)
    ]).isRequired,
    colors: PropTypes.objectOf(
        PropTypes.string
    ),
    onChange: PropTypes.func
}

export default WeightClassSelect;