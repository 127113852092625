import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {setAutopass, setRushtid} from "../../redux/actions/resultOptionsActions";
import Toggle from "../common/Toggle";

const ResultOptions = () => {
    const dispatch = useDispatch();
    const {autopass, rushtid} = useSelector(state => ({
        autopass: state.resultOptions.autopass,
        rushtid: state.resultOptions.rushtid
    }));

    return (
        <span>
            <Toggle
                label="Autopass"
                onToggle={() => dispatch(setAutopass(!autopass))}
                inline
                toggled={autopass}
            />
            <Toggle
                label="Rushtid"
                onToggle={() => dispatch(setRushtid(!rushtid))}
                inline
                toggled={rushtid}
            />
        </span>
    )
}

export default ResultOptions;