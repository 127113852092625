export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
export const STATIC_URL = "/static/media/"

export const sizes = {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
    HUGE: "HUGE",
    GIGANTIC: "GIGANTIC",
    IGNORE: "IGNORE"
};

export const iconSizes = {
    [sizes.SMALL]: "15px",
    [sizes.MEDIUM]: "20px",
    [sizes.LARGE]: "25px",
    [sizes.HUGE]: "40px",
    [sizes.GIGANTIC]: "100rem"
};