import {isDev} from "./constants";

export const mapApiKey = "AIzaSyAdB55iuvy-d9Z0yEKfdSrXRKA8gjt7vxg";

export const getResultURL = isDev
    ? "https://bompengeapiet-test.azurewebsites.net/api/bomstasjoner/findRouteDescriptions"
    : "https://dibkunnskapapi.azure-api.net/api/bomstasjoner/findRouteDescriptions";

export const getTollStationsURL = isDev
    ? "https://bompengeapiet-test.azurewebsites.net/api/bomstasjoner/GetBomstasjoner"
    : "https://dibkunnskapapi.azure-api.net/api/bomstasjoner/GetBomstasjoner";

export const getVehicleOptionsURL = isDev
    ? "https://bompengeapiet-test.azurewebsites.net/api/bomstasjoner/GetGuidata"
    : "https://dibkunnskapapi.azure-api.net/api/bomstasjoner/GetGuidata";

export const getGooglePlacesURL = data => {
    return isDev
        ? `https://bompengeapiet-test.azurewebsites.net/api/Address/GetGooglePlaces/${data}/false`
        : `https://dibkunnskapapi.azure-api.net/api/Address/GetGooglePlaces/${data}/false`;
};

/* export const getResultURL =
    "https://bompengeapiet-test.azurewebsites.net/api/bomstasjoner/findRouteDescriptions";
export const getTollStationsURL =
    "https://bompengeapiet-test.azurewebsites.net/api/bomstasjoner/GetBomstasjoner";
export const getVehicleOptionsURL =
    "https://bompengeapiet-test.azurewebsites.net/api/bomstasjoner/GetGuidata";
export const getGooglePlacesURL = data => {
    return `https://bompengeapiet-test.azurewebsites.net/api/Address/GetGooglePlaces/${data}/false`;
}; */
