/* eslint-disable */

import React, {useRef, useEffect} from "react";
import {compose, withProps} from "recompose";
import {withScriptjs, withGoogleMap, GoogleMap} from "react-google-maps";
import {mapStyle} from "../../config/mapStyle";
import {mapApiKey} from "../../config/apiConfig";
import positionConfig from "../../config/positionConfig";

const MapContainer = React.memo(compose(
    withProps({
        googleMapURL:
            `https://maps.googleapis.com/maps/api/js?key=${ 
                mapApiKey 
            }&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{height: "100%"}} />,
        containerElement: <div style={{height: "100vh"}} />,
        mapElement: <div style={{height: "100%"}} />
    }),
    withScriptjs,
    withGoogleMap
)(({position, markers, routes, bounds, getRef}) => {
    const mapRef = useRef(false);

    useEffect(() => {
        if (bounds) mapRef.current.fitBounds(bounds);
    }, [bounds]);

    useEffect(() => {
        getRef(mapRef);
    },[]);

    let pos = position;

    /* if (window.location !== window.parent.location) {
        pos = positionConfig.nord;
    } */

    return (
        <GoogleMap
            zoom={pos.zoom}
            center={pos.pos}
            options={{
                styles: mapStyle,
                disableDefaultUI: true,
                zoomControl: true
            }}
            ref={mapRef}
        >
            {markers}
            {routes}
        </GoogleMap>
    );
}));

export default MapContainer;
