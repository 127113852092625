
import React from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        padding: "1rem 1.5rem 1rem 0"
    },
    name: {
        display: "inline",
        flex: "1 0 40%",
        fontSize: "1.2rem",
        fontWeight: "800",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left"
    },
    info: {
        display: "inline",
        flex: "1 0 15%",
        padding: "0 0.5rem",
        fontSize: "1.1rem",
        fontWeight: "700",
        color: "rgba(0, 0, 0, 0.54)",
        whiteSpace: "nowrap"
    },
    price: {
        display: "inline",
        textAlign: "right",
        fontSize: "1.2rem",
        fontWeight: "700",
        color: theme.textColor,
        whiteSpace: "nowrap"
    }
}));

const RouteSummary = props => {
    const {
        name,
        distance,
        duration,
        price
    } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.name}>
                {name}
            </div>
            <span className={classes.info}>
                {distance} km
            </span>
            <span className={classes.info}>
                {duration}
            </span>
            <span className={classes.price}>
                {price}
            </span>
        </div>
    );
};

RouteSummary.propTypes = {
    name: PropTypes.string.isRequired,
    distance: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    duration: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    price: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
}

export default RouteSummary;
