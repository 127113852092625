import React, {useRef} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import {InfoBox} from "react-google-maps/lib/components/addons/InfoBox";
import {
    findToll,
    lowerFirstLetter,
    openIntercomWithMsg
} from "../../utils/utils";

const useStyles = createUseStyles(theme => ({
    root: {
        top: "35px",
        whiteSpace: "nowrap",
        backgroundColor: theme.backgroundPrimary,
        color: theme.colorText,
        fontSize: "0.9rem",
        border: theme.defaultBorder + theme.colorPrimary,
        borderRadius: theme.borderRadius,
        padding: "0.5rem",
        opacity: "0",
        transition: "opacity 250ms",
        outline: "none",
        minWidth: "12rem"
    },
    fadeIn: {
        opacity: "1"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "600",
        marginBottom: "0.5rem"
    },
    title: {
        fontSize: "1rem"
    },
    road: {
        marginLeft: "2rem"
    },
    result: {
        fontWeight: "600",
        marginLeft: "0.5rem"
    },
    lastRow: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "0.5rem"
    },
    button: {
        cursor: "pointer",
        color: theme.colorPrimary,
        userSelect: "none"
    },
    price: {
        margin: "0.3rem",
        paddingLeft: "0.8rem",
        listStyleType: "none",
        listStylePosition: "inside"
    }
}));

const InfoBoxComponent = props => {
    const {infoBoxRef, station, visible, setVisible, fade, setFade} = props;
    const classes = useStyles();
    const formData = useSelector(state => state.formData);
    const reportErrorButton = useRef(0);

    const vehicleName =
        formData && formData.weightClass && formData.weightClass.Idx === "1"
            ? "Personbil"
            : "Lastebil";

    let rushPeriod = [];
    if (station.Avgifter.length > 1) {
        rushPeriod = station.Avgifter.filter(toll => {
            return toll.Rushperiod_id !== "";
        }).map(toll => {
            return toll.Rushperiod_id;
        });
    }

    let extraFB = null;
    if (station.Avgifter.length > 1) {
        extraFB = station.Avgifter.find(toll => {
            if (vehicleName === "Personbil") {
                if (formData.smallVehicleType.Id === "lnu") {
                    return (
                        toll.Feetype_id === "fb" &&
                        toll.Size_type_id === "0-6" &&
                        toll.Vehicle_type_id === "lnu"
                    );
                }
                return (
                    toll.Feetype_id === "fb" &&
                    toll.Size_type_id === "0-6" &&
                    toll.Vehicle_type_id === ""
                );
            }
            return toll.Feetype_id === "fb" && toll.Size_type_id === "8-10";
        });
    }

    const toll = findToll(station, formData, false);
    const rushToll = findToll(station, formData, true);

    return (
        <InfoBox
            defaultPosition={
                new window.google.maps.LatLng({
                    lat: Number(station.Latitude),
                    lng: Number(station.Longitude)
                })
            }
            visible={visible}
            options={{
                closeBoxURL: "",
                disableAutoPan: true,
                pane: "mapPane",
                enableEventPropagation: false,
                pixelOffset: new window.google.maps.Size(0, 5),
                maxWidth: 0,
                zIndex: 999
            }}
            onDomReady={() => {
                reportErrorButton.current.addEventListener("click", () =>
                    openIntercomWithMsg(`Feil på bomstasjon ${station.Navn}: `)
                );
            }}
        >
            <div
                className={`${classes.root} ${fade && classes.fadeIn}`}
                ref={infoBoxRef}
                tabIndex="-1"
                onBlur={() => setFade(false)}
                onTransitionEnd={() => {
                    if (!fade) setVisible(false);
                }}
            >
                <div className={classes.header}>
                    <span className={classes.title}>{station.Navn}</span>
                    <span className={classes.road}>{station.Vegnr}</span>
                </div>
                {formData && (
                    <div className={classes.row}>
                        {`Priser for ${vehicleName.toLowerCase()}
                            (${
                    formData.weightClass.Id === "l"
                        ? formData.smallVehicleType.Navn &&
                                      lowerFirstLetter(
                                          formData.smallVehicleType.Navn
                                      )
                        : formData.bigVehicleType.Navn &&
                                      lowerFirstLetter(
                                          formData.bigVehicleType.Navn
                                      )
                    })`}
                        :
                        <ul className={classes.price}>
                            {toll && toll.Feetype_id !== "fb" && (
                                <li>
                                    Full:{" "}
                                    <span className={classes.result}>
                                        {toll.PrisNice}
                                    </span>
                                </li>
                            )}
                            {toll && toll.Feetype_id !== "fb" && (
                                <li>
                                    Autopass:{" "}
                                    <span className={classes.result}>
                                        {toll.PrisRabbatertNice}
                                    </span>
                                </li>
                            )}
                            {extraFB && (
                                <>
                                    <li>
                                        Ferjebillett full:{" "}
                                        <span className={classes.result}>
                                            {extraFB.PrisNice}
                                        </span>
                                    </li>
                                    <li>
                                        Ferjebillett autopass:{" "}
                                        <span className={classes.result}>
                                            {extraFB.PrisRabbatertNice}
                                        </span>
                                    </li>
                                </>
                            )}
                            {rushToll && rushToll.Feetype_id !== "fb" && (
                                <li>
                                    Rushtid:{" "}
                                    <span className={classes.result}>
                                        {rushToll.PrisNice} (
                                        {rushToll.PrisRabbatertNice.replace(
                                            "kr. ",
                                            ""
                                        )}
                                        )
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {station.Autopassrabatt &&
                    toll &&
                    toll.PrisNice !== toll.PrisRabbatertNice && (
                    <div className={classes.row}>
                        Autopass rabatt:
                        <span className={classes.result}>
                            {station.Autopassrabatt}%
                        </span>
                    </div>
                )}
                {rushPeriod.length > 0 && (
                    <div className={classes.row}>
                        Rushtid:
                        <span className={classes.result}>
                            {rushPeriod[0]}, {rushPeriod[1]}
                        </span>
                    </div>
                )}
                {station.Timesregel && <div>Timesregel</div>}
                {station.Informasjon}
                <div className={classes.lastRow}>
                    <span
                        ref={reportErrorButton}
                        type="button"
                        className={classes.button}
                    >
                        Meld feil
                    </span>
                </div>
            </div>
        </InfoBox>
    );
};

InfoBoxComponent.propTypes = {
    infoBoxRef: PropTypes.instanceOf(Object).isRequired,
    station: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array])
    ).isRequired,
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
    fade: PropTypes.bool.isRequired,
    setFade: PropTypes.func.isRequired
};

export default InfoBoxComponent;
