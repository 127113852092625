import React, {useState} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    button: {
        padding: "0.5rem 3rem",
        color: "white",
        fontFamily: "inherit",
        fontSize: "1.2rem",
        fontWeight: "800",
        userSelect: "none",
        backgroundColor: theme.colorPrimary,
        border: theme.defaultBorder,
        borderRadius: theme.borderRadius,
        outline: "blue"
    }
}));

const Submit = props => {
    const { 
        children, 
        onClick, 
        validate
    } = props;
    const classes = useStyles();
    const [active, setActive] = useState(true);

    return (
        <button
            className={`${classes.button} gtm-submit`}
            onClick={onClick}
            onMouseEnter={() => validate && setActive(validate)}
            disabled={!active}
            style={{
                cursor: active ? "pointer" : "not-allowed"
            }}
            type="button"
        >
            {children}
        </button>
    );
};

Submit.defaultProps = {
    children: null,
    validate: null,
    onClick: () => {}
}

Submit.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]),
    onClick: PropTypes.func,
    validate: PropTypes.func
};

export default Submit;
