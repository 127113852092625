import {SET_VEHICLEOPTIONS} from "../actions/vehicleOptionsActions";

export const vehicleOptionsReducer = (state = {}, {type, vehicleOptions}) => {
    switch (type) {
        case SET_VEHICLEOPTIONS:
            return vehicleOptions;
        default:
            return state;
    }
};
