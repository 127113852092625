import {
    SET_TOLLSTATIONS,
    SET_VISIBLETOLLSTATIONS
} from "../actions/tollStationsActions";

export const tollStationsReducer = (state = [], {type, stations}) => {
    switch (type) {
        case SET_TOLLSTATIONS:
            return stations;
        case SET_VISIBLETOLLSTATIONS:
            return state.map(station => {
                if (
                    stations.find(el => {
                        return station.Navn === el.Navn;
                    }) !== undefined
                ) {
                    return {
                        ...station,
                        Visible: true
                    };
                }
                return {
                    ...station,
                    Visible: false
                };
            });
        default:
            return state;
    }
};
