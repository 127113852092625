import {
    SET_AUTOCOMPLETE,
    RESET_AUTOCOMPLETE
} from "../actions/autoCompleteActions";

export const autoCompleteReducer = (state = {}, {type, payload}) => {
    switch (type) {
        case SET_AUTOCOMPLETE:
            if (state[payload.id] !== undefined) {
                const newState = {...state};
                newState[payload.id] = payload.data;
                return newState;
            }
            return {
                ...state,
                [payload.id]: payload.data
            };

        case RESET_AUTOCOMPLETE:
            return {
                ...state,
                [payload.id]: []
            };
        default:
            return state;
    }
};