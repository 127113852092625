import {
    SET_FROM,
    SET_TO,
    ADD_VIA,
    REMOVE_VIA,
    SET_WEIGHTCLASS,
    SET_BIGVEHICLETYPE,
    SET_SMALLVEHICLETYPE,
    SET_ROUNDTRIP,
    SET_FORMDATA
} from "../actions/formDataActions";
import {vehicleOptionsCookie} from "../../utils/cookies";

const initialState = () => {
    const {
        getWeightClass,
        getSmallVehicleType,
        getBigVehicleType
    } = vehicleOptionsCookie();

    return {
        from: "",
        to: "",
        via: [],
        weightClass: getWeightClass(),
        smallVehicleType: getSmallVehicleType(),
        bigVehicleType: getBigVehicleType(),
        roundTrip: "0"
    };
};

const setFrom = (state, payload) => {
    return {
        ...state,
        from: payload
    };
};

const setTo = (state, payload) => {
    return {
        ...state,
        to: payload
    };
};

const addVia = (state, payload) => {
    if (state.via.find(el => el.inputId === payload.inputId)) {
        return {
            ...state,
            via: state.via.map(el => {
                if (el.inputId === payload.inputId) {
                    return payload;
                }
                return el;
            })
        }
    }
    return {
        ...state,
        via: state.via.concat(payload)
    }
};

const removeVia = (state, payload) => {
    return {
        ...state,
        via: state.via.filter(el => el.inputId !== payload)
    }
};

const setWeightClass = (state, payload) => {
    const {setWeightClassCookie} = vehicleOptionsCookie();
    if (payload === null) return state;
    setWeightClassCookie(payload);
    return {
        ...state,
        weightClass: payload
    };
};

const setSmallVehicleType = (state, payload) => {
    const {setSmallVehicleTypeCookie} = vehicleOptionsCookie();
    if (payload === null) return state;
    setSmallVehicleTypeCookie(payload);
    return {
        ...state,
        smallVehicleType: payload
    };
};

const setBigVehicleType = (state, payload) => {
    const {setBigVehicleTypeCookie} = vehicleOptionsCookie();
    if (payload === null) return state;
    setBigVehicleTypeCookie(payload);
    return {
        ...state,
        bigVehicleType: payload
    };
};

const setRoundTrip = (state, payload) => {
    return {
        ...state,
        roundTrip: payload ? "1" : "0"
    };
};

export const formDataReducer = (state = initialState(), {type, payload}) => {
    switch (type) {
        case SET_FROM:
            return setFrom(state, payload);
        case SET_TO:
            return setTo(state, payload);
        case ADD_VIA:
            return addVia(state, payload);
        case REMOVE_VIA:
            return removeVia(state, payload);
        case SET_WEIGHTCLASS:
            return setWeightClass(state, payload);
        case SET_SMALLVEHICLETYPE:
            return setSmallVehicleType(state, payload);
        case SET_BIGVEHICLETYPE:
            return setBigVehicleType(state, payload);
        case SET_ROUNDTRIP:
            return setRoundTrip(state, payload);
        case SET_FORMDATA:
            return payload;
        default:
            return state;
    }
};