import React from "react";
import PropTypes from "prop-types";
import {Marker} from "react-google-maps";
import {STATIC_URL} from "../../config/constants";

const markers = {
    bomstasjon: {
        url: `${STATIC_URL}marker.svg`,
        scaledSize: {
            width: 50,
            height: 42
        }
    },
    ferge: {
        url: `${STATIC_URL}ferge.svg`,
        scaledSize: {
            width: 50,
            height: 42
        }
    }
};

const MarkerComponent = ({station, onClick}) => {
    return (
        <Marker
            icon={
                station.Navn.includes("Ferje")
                    ? markers.ferge
                    : markers.bomstasjon
            }
            defaultPosition={{
                lat: Number(station.Latitude),
                lng: Number(station.Longitude)
            }}
            onClick={onClick}
            visible={station.Visible}
        />
    );
};

MarkerComponent.defaultProps = {
    onClick: () => {}
};

MarkerComponent.propTypes = {
    station: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.array
        ])
    ).isRequired,
    onClick: PropTypes.func
};

export default MarkerComponent;
