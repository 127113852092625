import React, {Fragment, useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {useTheme} from "react-jss";
import PropTypes from "prop-types";
import {Polyline} from "react-google-maps";
import {selectRoute} from "../../redux/actions/resultActions";
import {getTollStations} from "../../redux/actions/tollStationsActions";
import MapContainer from "./MapContainer";
import MapStation from "./MapStation";
import {getBounds} from "../../utils/utils";
import {setGeolocation} from "../../redux/actions/geolocationActions";

const polylineOptions = theme => ({
    selected: {
        clickable: false,
        strokeColor: theme.colorPrimary,
        strokeWeight: 5,
        zIndex: 1
    },
    alternative: {
        clickable: false,
        strokeColor: theme.colorPrimary,
        strokeWeight: 2,
        zIndex: 0
    },
    clickable: {
        clickable: true,
        strokeColor: theme.colorGrey,
        strokeOpacity: 0,
        strokeWeight: 50,
        zIndex: 2
    }
});

const MapComponent = ({match, history}) => {
    const [bounds, setBounds] = useState(null);
    const [mapRef, setMapRef] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();

    const {
        formData,
        geolocation,
        tollStations,
        result
    } = useSelector(state => ({
        formData: state.formData,
        geolocation: state.geolocation,
        tollStations: state.tollStations,
        result: state.result
    }));

    useEffect(() => {
        dispatch(getTollStations());
    }, []);

    useEffect(() => {
        if (result.Tur && result.Tur[0] && mapRef) {
            setBounds(getBounds(result.Tur[0].Path));
        }
    }, [result]);

    useEffect(() => {
        if (match.params.location) {
            try {
                dispatch(setGeolocation(match.params.location));
            } catch {
                if (match.url !== "/rute") history.replace("");
            }
        }
    }, [match.params.location]);

    const tollMarkers = tollStations.map(station => {
        if (station.Visible && (!station.Country || station.Country === "Norge"))
            return (
                <MapStation
                    key={`${station.Latitude}_${station.Longitude}`}
                    station={station}
                    formData={formData}
                />
            );
        return null;
    });

    let routes = null;
    if (result.Tur) {
        routes = result.Tur.map((route, index) => (
            <Fragment key={`${route.Name}_${route.Meters}`}>
                <Polyline
                    path={route.Path}
                    options={
                        route.Selected
                            ? polylineOptions(theme).selected
                            : polylineOptions(theme).alternative
                    }
                />
                <Polyline
                    path={route.Path}
                    onClick={() => dispatch(selectRoute(index))}
                    options={polylineOptions(theme).clickable}
                />
            </Fragment>
        ));
    }

    return (
        <MapContainer
            getRef={setMapRef}
            markers={tollMarkers}
            routes={routes}
            position={geolocation}
            bounds={bounds}
        />
    );
};

MapComponent.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired
}

export default withRouter(MapComponent);
