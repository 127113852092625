import React from "react";
import {useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import RouteResult from "./RouteResult";
import {selectRoute} from "../../redux/actions/resultActions";

const useStyles = createUseStyles({
    root: {
        height: "100%",
        display: "flex",
        flexFlow: "column",
        overflowY: "auto"
    },
    wrapper: {
        maxHeight: "100%",
        display: "flex",
        flexFlow: "column"
    }
});

const Result = () => {
    const classes = useStyles();
    const result = useSelector(state => state.result);
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {result.Tur &&
                    result.Tur.map((route, index) => (
                        <RouteResult
                            key={`${route.Name}_${route.Meters}`}
                            id={index}
                            tur={route}
                            retur={result.Retur && result.Retur[index]}
                            selected={route.Selected}
                            selectRoute={selectRoute}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Result;
