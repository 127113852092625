import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
import {createUseStyles, useTheme} from "react-jss";
import WeightClassSelect from "./WeightClassSelect";
import WeightClassOption from "./WeightClassOption";
import DropdownSelect from "./DropdownSelect";
import Toggle from "../common/Toggle";
import Loading from "../../assets/svg/loading.svg";
import Icon from "../common/Icon";
import Car from "../../assets/svg/Car";
import Truck from "../../assets/svg/Truck";
import {getVehicleOptions} from "../../redux/actions/vehicleOptionsActions";
import {
    setWeightClass,
    setSmallVehicleType,
    setBigVehicleType,
    setRoundTrip
} from "../../redux/actions/formDataActions";

const useStyles = createUseStyles({
    root: {
        margin: "1rem 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    loader: {
        display: "inline",
        margin: "0 auto"
    },
    toggle: {
        margin: "0 0.2rem"
    }
});

const VehicleOptions = ({submit}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const {vehicleOptions, formData} = useSelector(state => ({
        vehicleOptions: state.vehicleOptions,
        formData: state.formData
    }));

    useEffect(() => {
        dispatch(getVehicleOptions());
    }, []);

    return (
        <div className={classes.root}>
            {vehicleOptions.Vektverdier ? (
                <>
                    <WeightClassSelect
                        selected={vehicleOptions.Vektverdier.find(
                            el => el.Idx === formData.weightClass.Idx
                        )}
                        onChange={selected => dispatch(setWeightClass(selected))}
                        colors={{
                            default: theme.colorGray,
                            selected: theme.colorPrimary,
                            hover: "rgba(4, 162, 176, 0.1)"
                        }}
                    >
                        <WeightClassOption
                            value={vehicleOptions.Vektverdier.find(
                                el => el.Navn === "Liten bil"
                            )}
                        >
                            <Car height="40" width="40" />
                        </WeightClassOption>
                        <WeightClassOption
                            value={vehicleOptions.Vektverdier.find(
                                el => el.Navn === "Stor bil"
                            )}
                        >
                            <Truck height="34" width="40" />
                        </WeightClassOption>
                    </WeightClassSelect>
                    {formData.weightClass &&
                        formData.weightClass.Idx === "1" ? (
                            <DropdownSelect
                                key="litenbil"
                                options={vehicleOptions.Litenbilverdier}
                                selected={formData.smallVehicleType}
                                setSelected={selected =>
                                    dispatch(setSmallVehicleType(selected))
                                }
                                submit={submit}
                            />
                        ) : (
                            <DropdownSelect
                                key="storbil"
                                options={vehicleOptions.Storbilverdier}
                                selected={formData.bigVehicleType}
                                setSelected={selected =>
                                    dispatch(setBigVehicleType(selected))
                                }
                                submit={submit}
                            />
                        )}
                    <div className={classes.toggle}>
                        <Toggle
                            label="Retur?"
                            size={1.2}
                            onToggle={val => dispatch(setRoundTrip(val))}
                            submit={submit}
                            toggled={formData.roundTrip === "1"}
                        />
                    </div>
                </>
            ) : (
                <Icon className={classes.loader} size="HUGE" svg={Loading} />
            )}
        </div>
    );
};

/* <img
                    className={classes.loader}
                    src={Loading}
                    alt=""
                    height="100rem"
                    width="100rem"
                /> */

VehicleOptions.propTypes = {
    submit: PropTypes.func.isRequired
};

export default VehicleOptions;
