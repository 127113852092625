/* eslint-disable */
import React from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import ExpansionPanel from "../common/ExpansionPanel";
import {openIntercomWithMsg, formatPrice} from "../../utils/utils";

const useStyles = createUseStyles(theme => ({
    root: {
        width: "90%",
        borderTop: "1px solid rgba(0,0,0,.125)",
        borderBottom: "5px solid rgb(250,0,0)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: "0"
        },
        "&:before": {
            display: "none"
        }
    },
    summary: {
        padding: "0.25rem 0 0.25rem 1rem",
        width: "90%",
        display: "flex",
        justifyContent: "space-between"
    },
    name: {
        fontSize: "1.1rem",
        fontWeight: "500",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    price: {
        fontSize: "1.1rem",
        textAlign: "right",
        fontWeight: "500",
        position: "relative"
    },
    button: {
        backgroundColor: "inherit",
        border: "none",
        outline: "none",
        padding: "0",
        cursor: "pointer",
        color: theme.colorPrimary
    },
    details: {
        lineHeight: "1rem"
    },
    star: {
        color: theme.colorPrimary,
        fontSize: "1.3rem",
        fontWeight: "900",
        margin: "0 0.25rem"
    },
    timesregel: {
        display: "flex"
    }
}));

const ChargePoint = ({station}) => {
    const classes = useStyles();
    const {autopass, rushtid, formData} = useSelector(state => ({
        autopass: state.resultOptions.autopass,
        rushtid: state.resultOptions.rushtid,
        formData: state.formData
    }));

    let extraFB = null;
    let bp = null;
    if (station.Avgifter.length > 1) {
        extraFB = station.Avgifter.find(toll => {
            return toll.Feetype_id === "fb";
        });
        bp = station.Avgifter.find(toll => {
            return toll.Feetype_id === "bp";
        });
    }

    const rushIndex = station.Avgifter.findIndex(toll => {
        return (
            toll.Feetype_id === "bp" &&
            (rushtid ? toll.Rushstart !== "" : toll.Rushstart === "")
        );
    });

    let discountedPrice = 0;
    let price = 0;
    const avgift = station.Avgifter[rushIndex === -1 ? 0 : rushIndex];

    if (avgift) {
        discountedPrice = avgift.PrisRabbattert;
        price = avgift.Pris;
    }

    if (extraFB) {
        discountedPrice += extraFB.PrisRabbattert;
        price += extraFB.Pris;
    }

    const summary = (
        <div className={classes.summary}>
            <span className={classes.name}>{station.Navn}</span>
            <span className={classes.price}>
                {station.Avgifter.length > 0
                    ? autopass
                        ? formatPrice(discountedPrice)
                        : formatPrice(price)
                    : "kr 0"}
                {station.TimesregelNavn !== "" && autopass && (
                    <span
                        className={classes.star}
                        style={{position: "absolute"}}
                    >
                        *
                    </span>
                )}
            </span>
        </div>
    );

    const isToll = station.Avgifter.length > 0;
    let rushtime = [];

    if (station.Avgifter.length > 1) {
        rushtime = station.Avgifter.filter(toll => {
            return toll.Rushperiod_id !== "";
        }).map(toll => {
            return toll.Rushperiod_id;
        });
    }

    return (
        <ExpansionPanel
            summary={summary}
            icon
            verticalPadding="0.25rem"
            borderLeft="small"
            animationSpeed={300}
        >
            {isToll ? (
                <div className={classes.details}>
                    {extraFB &&
                        (autopass ? (
                            <>
                                <div>Bompenger: {bp.PrisRabbatertNice}</div>
                                <div>
                                    Ferjebillett: {extraFB.PrisRabbatertNice}
                                </div>
                            </>
                        ) : (
                            <>
                                <div>Bompenger: {bp.PrisNice}</div>
                                <div>Ferjebillett: {extraFB.PrisNice}</div>
                            </>
                        ))}
                    <div className={classes.timesregel}>
                        {station.TimesregelNavn !== "" && autopass && (
                            <span className={classes.star}>*</span>
                        )}
                        {station.Timesregel && <span>Timesregel </span>}
                        {station.TimesregelNavn !== "" && (
                            <span style={{marginLeft: "0.2rem"}}>
                                {" "}
                                ({station.TimesregelNavn})
                            </span>
                        )}
                    </div>
                    {formData.weightClass.Idx === "1"
                        ? station.LNullutslipprabatt && (
                              <div>
                                  Nullutslipsrabatt:{" "}
                                  {station.LNullutslipprabatt} %
                              </div>
                          )
                        : station.SNullutslipprabatt && (
                              <div>
                                  Nullutslipsrabatt:{" "}
                                  {station.SNullutslipprabatt} %
                              </div>
                          )}
                    {price !== discountedPrice && (
                        <>
                            <div>
                                Autopass rabatt: {station.Autopassrabatt} %
                            </div>
                        </>
                    )}
                    {rushtime.length > 1 && (
                        <div>
                            Rushtid: {rushtime[0]} og {rushtime[1]}
                        </div>
                    )}
                </div>
            ) : (
                <div>Ingen avgifter</div>
            )}
            <button
                type="button"
                className={classes.button}
                onClick={() =>
                    openIntercomWithMsg(`Feil på bomstasjon ${station.Navn}: `)
                }
            >
                Meld feil
            </button>
        </ExpansionPanel>
    );
};

ChargePoint.propTypes = {
    station: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ChargePoint;
