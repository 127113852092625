import polylineDecoder from "@mapbox/polyline";
import {getResultURL} from "../../config/apiConfig";
import {addMonthlyCount, setMonthlyLimitReached} from "./monthlyLimitActions";
import {setOverloaded} from "./overloadedActions";
import {setVisibileTollStations} from "./tollStationsActions";

export const SELECT_ROUTE = "SELECT_ROUTE";
export const SET_RESULT = "SET_RESULT";
export const SET_RESULT_LOADING = "SET_RESULT_LOADING";

export const selectRoute = payload => {
    return {
        type: SELECT_ROUTE,
        payload
    };
};

export const setResultLoading = payload => {
    return {
        type: SET_RESULT_LOADING,
        payload
    };
};

export const setResult = payload => {
    return {
        type: SET_RESULT,
        payload
    };
};

export const getResult = () => {
    return async (dispatch, getState) => {
        const {
            formData,
            monthlyLimit: {limit, monthlyCount}
        } = getState();

        const request = {
            Fra: formData.from,
            Til: formData.to,
            Vialiste: formData.via,
            Dato_yyyymmdd: "",
            Tidspunkt_hhmm: "",
            Bilsize: formData.weightClass.Idx,
            Litenbiltype:
                formData.weightClass.Idx === "1"
                    ? formData.smallVehicleType.Idx
                    : "0",
            Storbiltype:
                formData.weightClass.Idx === "2"
                    ? formData.bigVehicleType.Idx
                    : "0",
            Billengdeunder: formData.weightClass.Idx === "1" ? "5" : "9",
            Retur: formData.roundTrip,
            Tidsreferanser: "0"
        };

        if (monthlyCount >= limit) {
            dispatch(setMonthlyLimitReached());
            return null;
        }

        dispatch(setResultLoading(true));

        return fetch(getResultURL, {
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": "5896465cf03d4dd8ac065e1d2a6f2556"
            },
            body: JSON.stringify(request)
        })
            .then(result => {
                if (result.status === 429) {
                    dispatch(setOverloaded(true));
                    return null;
                }
                return result.json();
            })
            .then(json => {
                return {
                    ...json,
                    Tur: json.Tur.map((route, index) => {
                        const {listPolylinePointsEncoded, ...rest} = route;
                        return {
                            ...rest,
                            Selected: index === 0,
                            Path: route.listPolylinePointsEncoded
                                .map(encodedPolyline => {
                                    return polylineDecoder
                                        .decode(encodedPolyline)
                                        .map(point => ({
                                            lat: point[0],
                                            lng: point[1]
                                        }));
                                })
                                .reduce((acc, val) => acc.concat(val), [])
                        };
                    })
                };
            })
            .then(payload => {
                dispatch(addMonthlyCount());
                dispatch(setResultLoading(false));
                dispatch(setResult(payload));
                dispatch(
                    setVisibileTollStations(
                        payload.Tur.map(route => {
                            return route.AvgiftsPunkter;
                        }).reduce((acc, val) => acc.concat(val), [])
                    )
                );
            });
    };
};
