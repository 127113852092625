import {getTollStationsURL} from "../../config/apiConfig";

export const SET_TOLLSTATIONS = "SET_TOLLSTATIONS";
export const GET_TOLLSTATIONS = "GET_TOLLSTATIONS";
export const SET_VISIBLETOLLSTATIONS = "SET_VISIBLETOLLSTATIONS";

export const setTollStations = stations => {
    return {
        type: SET_TOLLSTATIONS,
        stations
    };
};

export const getTollStations = () => {
    return async dispatch => {
        return fetch(getTollStationsURL, {
            method: "GET",
            headers: {
                "Ocp-Apim-Subscription-Key": "5896465cf03d4dd8ac065e1d2a6f2556"
            }
        })
            .then(result => {
                return result.json();
            })
            .then(result => {
                dispatch(
                    setTollStations(
                        result.map(station => ({
                            ...station,
                            Visible: true
                        }))
                    )
                );
            });
    };
};

export const setVisibileTollStations = stations => {
    return {
        type: SET_VISIBLETOLLSTATIONS,
        stations
    };
};
