import {getResult} from "./resultActions";
import {setSubmittedFormData} from "./submittedFormDataActions";

export const SET_FROM = "SET_FROM";
export const SET_TO = "SET_TO";
export const ADD_VIA = "ADD_VIA";
export const REMOVE_VIA = "REMOVE_VIA";
export const SET_WEIGHTCLASS = "SET_WEIGHTCLASS";
export const SET_SMALLVEHICLETYPE = "SET_SMALLVEHICLETYPE";
export const SET_BIGVEHICLETYPE = "SET_BIGVEHICLETYPE";
export const SET_ROUNDTRIP = "SET_ROUNDTRIP";
export const SET_FORMDATA = "SET_FORMDATA";

export const setFrom = payload => {
    return {
        type: SET_FROM,
        payload
    };
};

export const setTo = payload => {
    return {
        type: SET_TO,
        payload
    };
};

export const addVia = payload => {
    return {
        type: ADD_VIA,
        payload
    };
};

export const removeVia = payload => {
    return {
        type: REMOVE_VIA,
        payload
    };
};

export const setWeightClass = payload => {
    return {
        type: SET_WEIGHTCLASS,
        payload
    };
};

export const setSmallVehicleType = payload => {
    return {
        type: SET_SMALLVEHICLETYPE,
        payload
    };
};

export const setBigVehicleType = payload => {
    return {
        type: SET_BIGVEHICLETYPE,
        payload
    };
};

export const setRoundTrip = payload => {
    return {
        type: SET_ROUNDTRIP,
        payload
    };
};

export const setFormData = (payload) => {
    return {
        type: SET_FORMDATA,
        payload
    }
}

export const submitForm = (history = null) => {
    return (dispatch, getState) => {
        const {formData} = getState();
        if (history) {
            history.replace(`/rute/${btoa(JSON.stringify(formData))}`);
        }
        dispatch(setSubmittedFormData(formData));
        dispatch(getResult());
    };
};