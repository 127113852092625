import React from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
import SearchDropdown from "./SearchDropdown";
import ViaSearchDropdowns from "./ViaSearchDropdowns";
import {
    addVia,
    removeVia,
    setFrom,
    setTo
} from "../../redux/actions/formDataActions";

import {getAutoComplete, resetAutoComplete} from "../../redux/actions/autoCompleteActions";

const SearchDropdowns = props => {
    const {fromError, setFromError, toError, setToError, submit} = props;
    const dispatch = useDispatch();
    const {autoComplete, from, to, via} = useSelector(state => ({
        autoComplete: state.autoComplete,
        from: state.formData.from.Name,
        to: state.formData.to.Name,
        via: state.formData.via
    }));

    return (
        <>
            <SearchDropdown
                autoComplete={autoComplete[0]}
                getAutoComplete={(data, id) => dispatch(getAutoComplete(data, id))}
                error={fromError}
                setError={setFromError}
                placeholder="Fra"
                id={0}
                value={from}
                setValue={val => dispatch(setFrom(val))}
                resetAutoComplete={id => dispatch(resetAutoComplete(id))}
            />
            <ViaSearchDropdowns
                autoComplete={autoComplete}
                getAutoComplete={(data, id) => dispatch(getAutoComplete(data, id))}
                onAddVia={val => dispatch(addVia(val))}
                onRemoveVia={id => dispatch(removeVia(id))}
                resetAutoComplete={id => dispatch(resetAutoComplete(id))}
                submit={submit}
                via={via}
            />
            <SearchDropdown
                autoComplete={autoComplete[1]}
                getAutoComplete={(data, id) => dispatch(getAutoComplete(data, id))}
                error={toError}
                setError={setToError}
                placeholder="Til"
                id={1}
                value={to}
                setValue={val => dispatch(setTo(val))}
                resetAutoComplete={id => dispatch(resetAutoComplete(id))}
            />
        </>
    );
};

SearchDropdowns.propTypes = {
    fromError: PropTypes.bool.isRequired,
    setFromError: PropTypes.func.isRequired,
    toError: PropTypes.bool.isRequired,
    setToError: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
}

export default SearchDropdowns;
