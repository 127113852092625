import React from "react";
import {createUseStyles} from "react-jss";
import {useDispatch} from "react-redux";
import {setOverloaded} from "../../redux/actions/overloadedActions";
/* import {openIntercom} from "../../utils/utils"; */

const useStyles = createUseStyles(theme => ({
    container: {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
        "@media(max-width: 60rem)": {
            top: 0
        }
    },
    background: {
        position: "absolute",
        height: "100%",
        width: "100%",
        background: "grey",
        opacity: 0.5
    },
    dialog: {
        background: "white",
        border: `1px solid ${theme.colorPrimary}`,
        borderRadius: theme.borderRadius,
        padding: "0.5rem 1.5rem",
        /* display: "flex",
        justifyContent: "center",
        alignItems: "center", */
        /* height: "300px", */
        /* width: "500px", */
        maxWidth: "500px",
        zIndex: 1
    },
    button: {
        background: "none!important",
        border: "none",
        padding: "0!important",
        fontFamily: "arial, sans-serif",
        /* fontSize: "1.2rem", */
        color: "#069",
        textDecoration: "underline",
        cursor: "pointer"
    }
}));

const OverloadedDialog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setOverloaded(false))
    }

    return (
        <div className={classes.container}>
            <div className={classes.background} role="presentation" onClick={handleClick} />
            <div className={classes.dialog}>
                <h3 className={classes.header}>
                    På grunn av stor trafikk er bompengekalkulator midlertidig utilgjengelig. Prøv gjerne igjen om 10 minutter.
                </h3>
                <button type="button" className={classes.button} onClick={handleClick}>Ok</button>
            </div>
        </div>
    )
};

export default OverloadedDialog;
