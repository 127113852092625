import React, {useContext, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {createUseStyles, useTheme} from "react-jss";
import Header from "../menu/Header";
import RouteInfo from "../result/RouteInfo";
import {PrintContext} from "./Print"; // eslint-disable-line
import {findToll, durationLong, formatPrice} from "../../utils/utils";

const useStyles = createUseStyles(theme => ({
    document: {
        fontSize: "1.2vh"
    },
    page: {
        boxSizing: "border-box",
        backgroundColor: "white",
        padding: "6vh",
        width: "100vw",
        height: "100vh",
        "@media(min-width: 60rem)": {
            height: "90vh",
            width: `calc(90vh/${Math.sqrt(2)})`
        }
    },
    row: {
        display: "flex",
        justifyContent: "space-between"
    },
    trip: {
        paddingBottom: "1rem",
        borderTop: `1px solid ${theme.colorPrimary}`,
        borderBottom: `1px solid ${theme.colorPrimary}`,
        "&:(last-child)": {
            borderTop: "none"
        }
    },
    bold: {
        fontWeight: "700"
    },
    secondPage: {
        position: "absolute",
        top: "-100%"
    }
}));

const PrintDocument = props => {
    const {overflow, setOverflow, pageNumber} = props;

    const {
        tur,
        retur,
        turSum,
        returSum,
        totalSum,
        totalDistance,
        totalDuration
    } = useContext(PrintContext);

    const {autopass, rushtid, submittedFormData} = useSelector(state => ({
        autopass: state.resultOptions.autopass,
        rushtid: state.resultOptions.rushtid,
        submittedFormData: state.submittedFormData
    }));

    const classes = useStyles();
    const theme = useTheme();
    const pageRef = useRef(0);

    useEffect(() => {
        if (pageRef.current.offsetHeight < pageRef.current.scrollHeight) {
            setOverflow(true);
        } else {
            setOverflow(false);
        }
    }, []);

    const turStations = tur.AvgiftsPunkter.map(station => {
        const toll = findToll(station, submittedFormData, rushtid);
        let extraFB = null;
        if (station.Avgifter.length > 1) {
            extraFB = station.Avgifter.find(t => {
                return t.Feetype_id === "fb";
            });
        }

        return (
            <div className={classes.row} key={station.Navn}>
                <span>{station.Navn}</span>
                <span>
                    {autopass
                        ? formatPrice((toll ? toll.PrisRabbattert : 0) + (extraFB ? extraFB.PrisRabbattert : 0))
                        : formatPrice(toll.Pris + (extraFB ? extraFB.Pris : 0))}
                </span>
            </div>
        );
    });

    let returStations;
    if (retur)
        returStations = (
            <div
                className={classes.trip}
                style={{
                    borderTop: overflow
                        ? `1px solid ${theme.colorPrimary}`
                        : "none"
                }}
            >
                <RouteInfo
                    from={submittedFormData.to}
                    to={submittedFormData.from}
                    duration={retur.TidNice}
                    distance={retur.DistanseNice}
                    price={returSum}
                    bold
                />
                {retur.AvgiftsPunkter.map(station => {
                    const toll = findToll(station, submittedFormData, rushtid);
                    let extraFB = null;
                    if (station.Avgifter.length > 1) {
                        extraFB = station.Avgifter.find(t => {
                            return t.Feetype_id === "fb";
                        });
                    }
                    return (
                        <div className={classes.row} key={station.Navn}>
                            <span>{station.Navn}</span>
                            <span>
                                {autopass
                                    ? formatPrice(toll.PrisRabbattert + (extraFB ? extraFB.PrisRabbattert : 0))
                                    : formatPrice(toll.Pris + (extraFB ? extraFB.Pris : 0))}
                            </span>
                        </div>
                    );
                })}
            </div>
        );

    const result = (
        <div className={`${classes.row} ${classes.bold}`}>
            <span>Totalt:</span>
            <span>{totalDistance}km</span>
            <span>{durationLong(totalDuration)}</span>
            <span>{totalSum}</span>
        </div>
    );

    const date = new Date();
    const currentDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return (
        <div
            className={`${classes.document} ${pageNumber === "2" &&
                classes.secondPage}`}
        >
            <div className={classes.page} ref={pageRef}>
                <Header fontSize="4vh">bompengekalkulator.no</Header>
                <div>{currentDate}</div>
                <div className={classes.trip}>
                    <RouteInfo
                        from={submittedFormData.from}
                        to={submittedFormData.to}
                        duration={tur.TidNice}
                        distance={tur.DistanseNice}
                        price={turSum}
                        bold
                    />
                    {turStations}
                </div>

                {returStations && !overflow && returStations}
                {!overflow && result}
            </div>
            {overflow && (
                <div className={classes.page}>
                    {returStations}
                    {result}
                </div>
            )}
        </div>
    );
};

PrintDocument.propTypes = {
    overflow: PropTypes.bool.isRequired,
    setOverflow: PropTypes.func.isRequired,
    pageNumber: PropTypes.string.isRequired
};

export default PrintDocument;
