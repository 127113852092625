import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {createUseStyles, useTheme} from "react-jss";

const useStyles = createUseStyles({
    root: {
        width: "10rem",
        margin: "0 1.6rem"
    }
});

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        borderBottom: "1px solid gray",
        borderRadius: "0",
        boxShadow: "none",
        color: state.theme.colors.neutral20,
        fontSize: "1.2rem",
        cursor: "pointer",
        padding: "0 0.2rem",
        backgroundColor: state.isFocused
            ? state.theme.colors.primary25
            : provided.backgroundColor
    }),
    menu: provided => ({
        ...provided,
        marginTop: "1px"
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.theme.colors.neutral20,
        cursor: "pointer",
        backgroundColor: state.isSelected
            ? state.theme.colors.primary50
            : state.isFocused
                ? state.theme.colors.primary25
                : provided.backgroundColor,
        "&:hover": {
            backgroundColor: state.isSelected ? "" : provided.backgroundColor
        }
    }),
    indicatorsContainer: () => ({
        border: "none"
    }),
    valueContainer: () => ({
        padding: "0"
    })
};

const DropdownSelect = props => {
    const {options, setSelected, selected} = props;
    const classes = useStyles();
    const theme = useTheme();
    
    const handleChange = (option) => {
        setSelected(options.find(el => el.Id === option.value));
    }

    const selectOptions = options.map(el => ({
        value: el.Id,
        label: el.Navn
    })).slice(0, 5);

    return (
        <div className={classes.root}>
            <Select
                options={selectOptions}
                defaultValue={selected && selected.Id ? {value: selected.Id, label: selected.Navn} : selectOptions[0]}
                isSearchable={false}
                onChange={handleChange}
                styles={selectStyles}
                theme={selectTheme => ({
                    ...selectTheme,
                    borderRadius: theme.borderRadius,
                    colors: {
                        ...selectTheme.colors,
                        primary: theme.colorPrimary,
                        primary25: "rgba(4, 162, 176,0.1)",
                        primary50: "rgba(4, 162, 176,0.2)",
                        neutral20: theme.colorText,
                        neutral30: theme.colorText,
                        danger: theme.colorWarning
                    }
                })}
            />
        </div>
    );
};

DropdownSelect.defaultProps = {
    selected: null
}

DropdownSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool
            ])
        )
    ).isRequired,
    setSelected: PropTypes.func.isRequired,
    selected: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ])
    )
}

export default DropdownSelect;
