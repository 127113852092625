import React from "react";
import {createUseStyles} from "react-jss";
/* import {openIntercom} from "../../utils/utils"; */

const useStyles = createUseStyles(theme => ({
    button: {
        color: theme.colorPrimary,
        backgroundColor: "white",
        border: "1px solid",
        borderColor: theme.colorPrimary,
        borderRadius: theme.borderRadius,
        padding: "0.4rem 0.6rem",
        margin: "1rem",
        position: "fixed",
        top: "0",
        right: "0",
        zIndex: "5",
        "&:hover": {
            cursor: "pointer"
        }
    }
}));

const Button = () => {
    const classes = useStyles();
    return (
        <button
            className={classes.button}
            type="button"
            /* onClick={() => openIntercom()} */
        >
            Tilbakemelding
        </button>
    );
};

export default Button;
