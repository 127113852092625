import {
    SET_MONTHLY_COUNT,
    SET_MONTHLY_LIMIT_REACHED
} from "../actions/monthlyLimitActions";

export const monthlyLimitReducer = (
    state = {
        month: null,
        monthlyCount: 0,
        limit: 5,
        limitReached: false
    },
    {type, payload}
) => {
    switch (type) {
        case SET_MONTHLY_COUNT: {
            const {month, count} = payload;
            return {
                ...state,
                month,
                monthlyCount: count
            };
        }
        case SET_MONTHLY_LIMIT_REACHED:
            return {
                ...state,
                limitReached: true
            };
        default:
            return state;
    }
};
