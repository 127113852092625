import {
    getMonthlyCountCookie,
    setMonthlyCountCookie
} from "../../utils/cookies";

export const SET_MONTHLY_COUNT = "SET_MONTHLY_COUNT";
export const SET_MONTHLY_LIMIT_REACHED = "SET_MONTHLY_LIMIT_REACHED";

export const setMonthlyCount = (month, count) => {
    return {
        type: SET_MONTHLY_COUNT,
        payload: {month, count}
    };
};

export const setMonthlyLimitReached = () => {
    return {
        type: SET_MONTHLY_LIMIT_REACHED
    };
};

export const initMonthlyCount = () => {
    return async (dispatch, getState) => {
        const {
            monthlyLimit: {limit}
        } = getState();

        const [count, month] = getMonthlyCountCookie();
        const date = new Date();
        const currentMonth = date.getMonth();

        if (month !== currentMonth) {
            setMonthlyCountCookie(0);
            dispatch(setMonthlyCount(currentMonth, 0));
        } else {
            dispatch(setMonthlyCount(currentMonth, count));

            if (count >= limit) {
                dispatch(setMonthlyLimitReached(true));
            }
        }
    };
};

export const addMonthlyCount = () => {
    return async dispatch => {
        const [count, month] = getMonthlyCountCookie();
        setMonthlyCountCookie(count + 1);
        dispatch(setMonthlyCount(month, count + 1));
    };
};
