import {getGooglePlacesURL} from "../../config/apiConfig";

export const SET_AUTOCOMPLETE = "SET_AUTOCOMPLETE";
export const RESET_AUTOCOMPLETE = "RESET_AUTOCOMPLETE";

export const setAutoComplete = (data, id) => {
    return {
        type: SET_AUTOCOMPLETE,
        payload: {data, id}
    };
};

export const resetAutoComplete = id => {
    return {
        type: RESET_AUTOCOMPLETE,
        payload: {id}
    };
};

export const getAutoComplete = (data, id) => {
    return async dispatch => {
        try {
            const response = await fetch(getGooglePlacesURL(data), {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": "5896465cf03d4dd8ac065e1d2a6f2556"
                }
            });
            const autoCompleteData = await response.json();
            dispatch(setAutoComplete(autoCompleteData, id));
        }
        catch (event) {
            // TODO ADD ERROR HANDLING
            // eslint-disable-next-line
            console.log("Error: ", event);
        }
    };
};