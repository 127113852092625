import React, {useContext} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import {WeightClassContext} from "./WeightClassSelect";

const useStyles = createUseStyles({
    option: {
        flexGrow: "1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        background: "none",
        outline: "none",
        color: props => props.colors.default,
        "&:hover": {
            backgroundColor: "rgba(4, 162, 176, 0.1)",
            cursor: "pointer"
        }
    },
    selected: {
        color: props => props.colors.selected
    }
});

const WeightClassOption = props => {
    const {children, value} = props;
    const {
        selectedOption,
        selectOption,
        colors
    } = useContext(WeightClassContext);
    const classes = useStyles({...props, colors});
    const isSelected = value === selectedOption
    
    return (
        <button
            type="button"
            className={`${classes.option} ${isSelected && classes.selected}`}
            onClick={() => selectOption(value)}
        >
            {children}
        </button>
    );
};

WeightClassOption.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.objectOf(PropTypes.string).isRequired
};

export default WeightClassOption;
