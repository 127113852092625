import React from "react";
import PropTypes from "prop-types";
import AriaModal from "react-aria-modal";
import PrintView from "./PrintView"; // eslint-disable-line

const PrintModal = ({onExit}) => {
    return (
        <AriaModal
            titleText="Print"
            onExit={() => onExit(false)}
            focusDialog
        >
            <PrintView onExit={onExit}/>
        </AriaModal>
    );
}

PrintModal.propTypes = {
    onExit: PropTypes.func.isRequired
};

export default PrintModal;