import {SET_SUBMITTEDFORMDATA} from "../actions/submittedFormDataActions";

export const submittedFormDataReducer = (
    state = {
        from: null,
        to: null,
        via: [],
        weightClass: null,
        smallVehicleType: null,
        bigVehicleType: null,
        roundTrip: null
    },
    {type, payload}
) => {
    switch (type) {
        case SET_SUBMITTEDFORMDATA:
            return payload;
        default:
            return state;
    }
};
