import React from "react";
import {useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import Form from "../form/Form";
import Result from "../result/Result";
import Header from "./Header";
import Loading from "../../assets/svg/loading.svg";
import Footer from "./Footer";
import Icon from "../common/Icon";

const useStyles = createUseStyles(theme => ({
    root: {
        backgroundColor: theme.backgroundPrimary,
        display: "flex",
        flexFlow: "column",
        position: "relative",
        minHeight: "100vh",
        justifyContent: "space-between",
        "@media(min-width: 60rem)": {
            height: "100vh"
        }
    },
    wrapper: {
        display: "flex",
        flexFlow: "column",
        height: "100vh",
        justifyContent: "space-between"
    },
    loader: {
        display: "block",
        margin: "1rem auto",
        height: "100%",
        width: "50%"
    }
}));

const MainMenu = () => {
    const classes = useStyles();
    const resultLoading = useSelector(state => state.result.loading);

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div>
                    <Header href="http://bompengekalkulator.no">bompengekalkulator.no</Header>
                    <Form />
                </div>
                {resultLoading ? (
                    <div className={classes.loader}>
                        <Icon size="IGNORE" svg={Loading} />
                    </div>
                ) : (
                    <Result />
                )}
                <Footer />
            </div>
        </div>
    );
};

export default MainMenu;
