import {combineReducers} from "redux";
import {formDataReducer} from "./reducers/formDataReducer";
import {submittedFormDataReducer} from "./reducers/submittedFormDataReducer";
import {autoCompleteReducer} from "./reducers/autoCompleteReducer";
import {resultOptionsReducer} from "./reducers/resultOptionsReducer";
import {tollStationsReducer} from "./reducers/tollStationsReducer";
import {resultReducer} from "./reducers/resultReducer";
import {vehicleOptionsReducer} from "./reducers/vehicleOptionsReducer";
import {geolocationReducer} from "./reducers/geolocationReducer";
import {monthlyLimitReducer} from "./reducers/monthlyLimitReducer";
import {overloadedReducer} from "./reducers/overloadedReducer";

const rootReducer = combineReducers({
    autoComplete: autoCompleteReducer,
    formData: formDataReducer,
    submittedFormData: submittedFormDataReducer,
    resultOptions: resultOptionsReducer,
    tollStations: tollStationsReducer,
    result: resultReducer,
    vehicleOptions: vehicleOptionsReducer,
    geolocation: geolocationReducer,
    monthlyLimit: monthlyLimitReducer,
    overloaded: overloadedReducer
});

export default rootReducer;