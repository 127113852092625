import {getVehicleOptionsURL} from "../../config/apiConfig";
import {setWeightClass, setSmallVehicleType, setBigVehicleType} from "./formDataActions";
import {setOverloaded} from "./overloadedActions";

export const SET_VEHICLEOPTIONS = "SET_VEHICLEOPTIONS";

export const setVehicleOptions = vehicleOptions => {
    return {
        type: SET_VEHICLEOPTIONS,
        vehicleOptions
    };
};

export const getVehicleOptions = () => {
    return async (dispatch, getState) => {
        const {formData} = getState();
        return fetch(getVehicleOptionsURL, {
            method: "GET",
            headers: {
                "Ocp-Apim-Subscription-Key": "5896465cf03d4dd8ac065e1d2a6f2556"
            }
        })
            .then(result => {
                if (result.status === 429) {
                    dispatch(setOverloaded(true));
                    return null;
                }
                if (result.ok) {
                    return result.json();
                }
                return null;
            })
            .then(result => {
                if (!formData.weightClass) {
                    dispatch(
                        setWeightClass(
                            result.Vektverdier.find(
                                el => el.Defaultverdi === "1"
                            )
                        )
                    );
                }
                if (!formData.smallVehicleType) {
                    dispatch(
                        setSmallVehicleType(
                            result.Litenbilverdier.find(
                                el => el.Defaultverdi === "1"
                            )
                        )
                    );
                }
                if (!formData.bigVehicleType) {
                    dispatch(
                        setBigVehicleType(
                            result.Storbilverdier.find(
                                el => el.Defaultverdi === "1"
                            )
                        )
                    );
                }
                dispatch(setVehicleOptions(result));
            });
    };
};
