import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import ReactToPrint from "react-to-print";
import PrintDocument from "./PrintDocument"; // eslint-disable-line
import Submit from "../common/Submit";
import Exit from "../../assets/svg/Exit";

const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        backgroundColor: "white",
        width: "100vw",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
        "@media(min-width: 60rem)": {
            marginTop: "5vh",
            height: "90vh",
            width: `calc(90vh/${Math.sqrt(2)})`
        }
    },
    exitButton: {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        cursor: "pointer",
        position: "absolute",
        top: "0",
        right: "0",
        padding: "1rem"
    },
    bottom: {
        display: "flex",
        justifyContent: "space-evenly",
        position: "absolute",
        bottom: "2vh",
        width: "100%"
    },
    printButton: {
        flex: "1",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center"
    },
    pageSwitch: {
        flex: "1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.4rem",
        color: "gray"
    },
    pageSwitchButton: {
        fontSize: "1.5rem",
        backgroundColor: "white",
        border: "none",
        cursor: "pointer",
        color: "gray"
    }
});

const PrintView = ({onExit}) => {
    const [overflow, setOverflow] = useState(false);
    const [pageNumber, setPageNumber] = useState("1");
    const classes = useStyles();
    const document = useRef(0);

    const handleClick = () => {
        if (pageNumber === "1") {
            setPageNumber("2");
        } else {
            setPageNumber("1");
        }
    };

    return (
        <div className={classes.container}>
            <div ref={document}>
                <PrintDocument
                    pageNumber={pageNumber}
                    overflow={overflow}
                    setOverflow={setOverflow}
                />
            </div>
            <button
                className={classes.exitButton}
                type="button"
                onClick={() => onExit(false)}
            >
                <Exit height="3rem" width="3rem" fill="grey" />
            </button>
            <div className={classes.bottom}>
                {overflow &&<span style={{flex: "1"}}/>}
                <span className={classes.printButton}>
                    <ReactToPrint
                        trigger={() => <div><Submit>Print ut</Submit></div>}
                        content={() => document.current}
                    />
                </span>
                {overflow && (
                    <span className={classes.pageSwitch}>
                        <button type="button"className={classes.pageSwitchButton} onClick={handleClick}>
                            &lt;
                        </button>
                        {pageNumber} av 2
                        <button type="button" className={classes.pageSwitchButton} onClick={handleClick}>
                            &gt;
                        </button>
                    </span>
                )}
            </div>
        </div>
    );
};

PrintView.propTypes = {
    onExit: PropTypes.func.isRequired
};

export default PrintView;
