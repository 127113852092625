export const getMax = array => {
    let len = array.length;
    let max = -Infinity;
    // eslint-disable-next-line
    while (len--) {
        max = array[len] > max ? array[len] : max;
    }
    return max;
};

export const getMin = array => {
    let len = array.length;
    let min = Infinity;
    // eslint-disable-next-line
    while (len--) {
        min = array[len] < min ? array[len] : min;
    }
    return min;
};

// Gets the bounds(south west and north east point) for the given path
export const getBounds = path => {
    const latValues = path.map(latlng => latlng.lat);
    const lngValues = path.map(latlng => latlng.lng);
    const sw = {lat: getMin(latValues), lng: getMin(lngValues)};
    const ne = {lat: getMax(latValues), lng: getMax(lngValues)};
    return new window.google.maps.LatLngBounds(sw, ne);
};

// Check if form data has changed since last submit by comparing current data and previous data
export const submitValidate = (formData, prevFormData) => {
    return !Object.keys(formData).every(
        key => formData[key] === prevFormData[key]
    );
};

// Checks if the toll is for the correct vehicle selected
export const isVehicleType = (toll, formData) => {
    if (formData.weightClass) {
        if (formData.weightClass.Id === "s") {
            return (
                (toll.Vehicle_type_id === formData.weightClass.Id &&
                    formData.bigVehicleType.Id !== "snu") ||
                toll.Vehicle_type_id === formData.bigVehicleType.Id ||
                (toll.Vehicle_type_id === "" &&
                    formData.bigVehicleType.Id !== "snu")
            );
        }
        if (formData.weightClass.Id === "l") {
            return (
                (toll.Vehicle_type_id === formData.weightClass.Id &&
                    formData.smallVehicleType.Id !== "lnu" &&
                    formData.smallVehicleType.Id !== "lhyd") ||
                toll.Vehicle_type_id === formData.smallVehicleType.Id ||
                (toll.Vehicle_type_id === "" &&
                    formData.smallVehicleType.Id !== "lnu" &&
                    formData.smallVehicleType.Id !== "lhyd")
            );
        }
        return false;
    }
    return false;
};

// For stations with more than one toll, finds the correct toll for vehicle selected
export const findToll = (station, formData, rushtid) => {
    if (!station.Country || station.Country === "Norge") {
        if (station.Avgifter.length === 1) {
            if (isVehicleType(station.Avgifter[0], formData)) {
                return station.Avgifter[0];
            }
            return undefined;
        }
        if (station.Navn.includes("Ferje") && station.Avgifter.length > 1) {
            if (formData.smallVehicleType.Id === "lnu") {
                return station.Avgifter.find(toll => {
                    return toll.Vehicle_type_id === "lnu"
                });
            }
            return station.Avgifter.find(toll => {
                return toll.Feetype_id === "bp" && toll.Vehicle_type_id !== "mc";
            });
        }
        if (station.Avgifter.length > 1) {
            return station.Avgifter.find(toll => {
                if (toll.Feetype_id === "bp" && isVehicleType(toll, formData)) {
                    if (formData.weightClass.Id === "l") {
                        return rushtid
                            ? toll.Rushperiod_id !== "" &&
                                  (toll.Size_type_id === "0-6" ||
                                      toll.Size_type_id === "")
                            : toll.Rushperiod_id === "" &&
                                  (toll.Size_type_id === "0-6" ||
                                      toll.Size_type_id === "");
                    }
                    if (formData.weightClass.Id === "s") {
                        return rushtid
                            ? toll.Rushperiod_id !== "" &&
                                  (toll.Size_type_id === "8-10" ||
                                      toll.Size_type_id === "6.01-12.4" ||
                                      toll.Size_type_id === "")
                            : toll.Rushperiod_id === "" &&
                                  (toll.Size_type_id === "8-10" ||
                                      toll.Size_type_id === "6.01-12.4" ||
                                      toll.Size_type_id === "");
                    }
                }
                if (toll.Feetype_id === "fb") {
                    if (toll.Vehicle_type_id === formData.weightClass.Id) {
                        return true;
                    }
                    if (formData.weightClass.Id === "l") {
                        if (
                            (formData.smallVehicleType.Id === "lnu" ||
                                formData.smallVehicleType.Id === "lhyd") &&
                            toll.Vehicle_type_id === "lnu"
                        ) {
                            return (
                                toll.Size_type_id === "0-6" &&
                                toll.Vehicle_type_id === "lnu"
                            );
                        }
                        return (
                            toll.Size_type_id === "0-6" &&
                            toll.Vehicle_type_id === ""
                        );
                    }
                    return toll.Size_type_id === "8-10";
                }
                return undefined;
            });
        }
    }
    return null;
};

// Sums the toll price for the stations and options provided
export const sumToll = (stations, vehicleOptions, rushtid, autopass) =>
    stations.reduce((acc, station) => {
        const toll = findToll(station, vehicleOptions, rushtid);
        let extraFB = null;
        if (station.Avgifter.length > 1) {
            extraFB = station.Avgifter.find(t => {
                return t.Feetype_id === "fb";
            });
        }
        return (
            acc +
            (extraFB ? (autopass ? extraFB.PrisRabbattert : extraFB.Pris) : 0) +
            (toll ? (autopass ? toll.PrisRabbattert : toll.Pris) : 0)
        );
    }, 0);

// Converts the price to a string with 2 decimal points and , instead of .
export const formatPrice = price =>
    `kr. ${price
        .toFixed(2)
        .toString()
        .replace(".", ",")}`;

export const durationLong = duration => {
    const hours = Math.trunc(duration / 60);
    const minutes = Math.trunc(duration % 60);
    return `${hours > 0 ? `${hours} timer` : ""} ${
        minutes > 0 ? `${minutes} min` : ""
    }`;
};

export const durationShort = duration => {
    const hours = Math.trunc(duration / 60);
    const minutes = Math.trunc(duration % 60);
    return `${hours > 0 ? `${hours}t` : ""} ${
        minutes > 0 ? `${minutes}m` : ""
    }`;
};

export const lowerFirstLetter = string => {
    return string.charAt(0).toLowerCase() + string.slice(1);
};

export const openIntercom = () => {
    if (window && window.Intercom) window.Intercom("show");
};

export const openIntercomWithMsg = msg => {
    if (window && window.Intercom) window.Intercom("showNewMessage", msg);
};
