import React from "react";
import {createUseStyles, ThemeProvider} from "react-jss";
import MediaQuery from "react-responsive";
import IntercomButton from "./components/menu/IntercomButton";
import Menu from "./components/menu/Menu";
import MapComponent from "./components/map/MapComponent";
import LimitReached from "./components/menu/LimitReached";
import OverloadedDialog from "./components/menu/OverloadedDialog";
import {useMonthlyLimit, useOverloaded} from "./utils/hooks";

const theme = {
    colorPrimary: "#04a2b0",
    colorGray: "gray",
    backgroundPrimary: "#ffffff",
    colorText: "#444444",
    colorSelected: "#f2f2f2",
    colorWarning: "red",
    colorInactive: "#d9d7d7",
    borderRadius: "0.25rem",
    defaultBorder: "1px solid",
    isIE11:
        navigator &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("Trident") !== -1
};

const useStyles = createUseStyles({
    "@global": {
        html: {
            fontSize: "14px",
            boxSizing: "border-box"
        },
        body: {
            margin: "0",
            padding: "0",
            fontFamily: ["Palanquin", "sans-serif"],
            fontWeight: "500",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale"
        }
    },
    wrapper: {
        "@media(min-width: 60rem)": {
            display: "flex",
            boxSizing: "border-box"
        }
    },
    menu: {
        "@media(min-width: 60rem)": {
            flex: "0 0 30rem",
            position: "relative",
            zIndex: "2",
            overflow: "hidden"
        }
    },
    map: {
        "@media(min-width: 60rem)": {
            flex: "0 1 100%",
            position: "relative",
            zIndex: "1"
        }
    }
});

const App = () => {
    const classes = useStyles();
    const limitReached = useMonthlyLimit();
    const overloaded = useOverloaded();

    
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.wrapper}>
                <div className={classes.menu}>
                    <Menu />
                </div>
                <MediaQuery query="(min-width: 60rem)">
                    <IntercomButton />
                    <div className={classes.map}>
                        {!limitReached && <MapComponent />}
                    </div>
                </MediaQuery>
                {limitReached && <LimitReached />}
                {overloaded && <OverloadedDialog />}
            </div>
        </ThemeProvider>
    );
};

export default App;
