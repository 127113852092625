import {SET_GEOLOCATION} from "../actions/geolocationActions";
import positionConfig from "../../config/positionConfig";

export const geolocationReducer = (
    state = positionConfig.default,
    {type, location}
) => {
    switch (type) {
        case SET_GEOLOCATION:
            return positionConfig[location];
        default:
            return state;
    }
};