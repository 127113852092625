import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import MapMarker from "./MapMarker";
import InfoBox from "./InfoBox";

const MapStation = ({station}) => {

    const [infoBoxVisible, setInfoBoxVisible] = useState(false);
    const [fade, setFade] = useState(false);
    const infoBoxRef = useRef(0);

    const handleClick = () => {
        setInfoBoxVisible(true);
        setFade(true);
        infoBoxRef.current.focus();
    };

    return (
        <>
            <MapMarker
                station={station}
                onClick={handleClick}
            />
            <InfoBox
                infoBoxRef={infoBoxRef}
                visible={infoBoxVisible}
                setVisible={setInfoBoxVisible}
                station={station}
                fade={fade}
                setFade={setFade}
            />
        </>
    );
};

MapStation.propTypes = {
    station: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.array
        ])
    ).isRequired
}

export default MapStation;