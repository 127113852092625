import React, {useState} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
import {createUseStyles} from "react-jss";
import ExpansionPanel from "../common/ExpansionPanel";
import Print from "../print/Print";
import RouteDetails from "./RouteDetails";
import RouteSummary from "./RouteSummary";
import ResultOptions from "./ResultOptions";
import {selectRoute} from "../../redux/actions/resultActions";
import {sumToll, formatPrice, durationShort} from "../../utils/utils";

const useStyles = createUseStyles(theme => ({
    root: {
        overflowY: "auto",
        borderTop: "1px solid #d9d7d7",
        "&:last-child": {
            borderBottom: "1px solid #d9d7d7"
        }
    },
    expanded: {
        flex: (theme.isIE11 ? "1 1 auto" : "1")
    },
    notExpanded: {
        flex: "0 0 auto"
    },
    row: {
        padding: "0.5rem 1rem 1rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.colorPrimary
    },
    divider: {
        margin: "1rem",
        height: "1px",
        borderTop: "1px solid",
        color: theme.colorPrimary
    },
    button: {
        cursor: "pointer",
        userSelect: "none"
    }
}));

const RouteResult = props => {
    const {
        id,
        tur,
        retur,
        selected
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const {autopass, rushtid, submittedFormData} = useSelector(state => ({
        autopass: state.resultOptions.autopass,
        rushtid: state.resultOptions.rushtid,
        submittedFormData: state.submittedFormData
    }));

    const turSum = sumToll(
        tur.AvgiftsPunkter,
        submittedFormData,
        rushtid,
        autopass
    );
    
    let returSum = 0;
    if (retur) {
        returSum = sumToll(
            retur.AvgiftsPunkter,
            submittedFormData,
            rushtid,
            autopass
        );
    }

    const totalPriceFormated = formatPrice(retur ? turSum + returSum : turSum);
    const totalDistance = Math.round(
        (retur ? tur.Meters + retur.Meters : tur.Meters) / 1000
    );
    const totalDuration = (retur ? tur.Seconds + retur.Seconds : tur.Seconds) / 60;

    return (
        <div
            className={`${classes.root} ${isExpanded ? classes.expanded : classes.notExpanded}`}
        >
            <ExpansionPanel
                id={id}
                summary={
                    <RouteSummary
                        name={tur.Name}
                        distance={totalDistance}
                        duration={durationShort(totalDuration)}
                        price={totalPriceFormated}
                    />
                }
                icon
                iconDir="left"
                expanded={selected}
                selected={selected}
                setSelected={val => dispatch(selectRoute(val))}
                animationSpeed={300}
                getExpanded={setIsExpanded}
            >
                <RouteDetails
                    tur={tur}
                    retur={retur}
                    tripSum={turSum}
                    roundtripSum={returSum}
                />
                <div className={classes.row}>
                    <ResultOptions />
                    <div>
                        <Print
                            tur={tur}
                            retur={retur}
                            turSum={turSum}
                            returSum={returSum}
                            totalSum={totalPriceFormated}
                            totalDistance={totalDistance}
                            totalDuration={totalDuration}
                        />
                    </div>
                </div>
            </ExpansionPanel>
        </div>
    );
};

RouteResult.defaultProps = {
    retur: null
}

RouteResult.propTypes = {
    id: PropTypes.number.isRequired,
    tur: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.array
        ])
    ).isRequired,
    retur: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.array
        ])
    ),
    selected: PropTypes.bool.isRequired
}

export default RouteResult;
