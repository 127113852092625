import React from "react";
import {createUseStyles} from "react-jss";
import {openIntercom} from "../../utils/utils";

const useStyles = createUseStyles(theme => ({
    container: {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
        "@media(max-width: 60rem)": {
            top: 0
        }
    },
    background: {
        position: "absolute",
        height: "100%",
        width: "100%",
        background: "grey",
        opacity: 0.5
    },
    dialog: {
        background: "white",
        border: `1px solid ${theme.colorPrimary}`,
        borderRadius: theme.borderRadius,
        padding: "0.5rem 1.5rem",
        /* display: "flex",
        justifyContent: "center",
        alignItems: "center", */
        /* height: "300px", */
        /* width: "500px", */
        maxWidth: "500px",
        zIndex: 1
    },
    button: {
        background: "none!important",
        border: "none",
        padding: "0!important",
        fontFamily: "arial, sans-serif",
        color: "#069",
        textDecoration: "underline",
        cursor: "pointer"
    }
}));

const LimitReached = () => {
    const classes = useStyles();

    const handleClick = () => {
        openIntercom();
    };

    return (
        <div id="monthly_limit_reached" className={classes.container}>
            <div className={classes.background}></div>
            <div className={classes.dialog}>
                <h3 className={classes.header}>
                    Du har dessverre brukt opp antall søk på
                    bompengekalkulator.no denne måneden.
                </h3>
                <p>
                    Vi ønsker å holde bompengekalkulator.no gratis, men pga høye
                    kostnader til Google Maps må vi dessverre begrense antall
                    søk per bruker.
                </p>
                <p>
                    Vi anbefaler at du bruker en av disse{" "}
                    <a href="https://om.bompengekalkulator.no/apper">
                        appene/kjørebøkene
                    </a>{" "}
                    som har beregning av bompenger og ferjepriser integrert.
                </p>
                <p>
                    Vi tilbyr også et{" "}
                    <a href="https://om.bompengekalkulator.no/integrasjon">
                        API
                    </a>{" "}
                    til systemleverandører som kan integrere bompenger i sine
                    tjenester.
                </p>
                <p>
                    Ta gjerne{" "}
                    <button
                        className={classes.button}
                        type="button"
                        onClick={handleClick}
                    >
                        {/* eslint-disable-line */}
                        kontakt
                    </button>{" "}
                    ved spørsmål eller tilbakemelding.
                </p>
            </div>
        </div>
    );
};

export default LimitReached;
