import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    root: {
        display: "flex",
        flexFlow: "column"
    },
    navMenu: {
        display: "flex",
        justifyContent: "space-evenly",
        fontSize: "18px",
        margin: "0.8rem 0",
        bottom: "0",
        "& a": {
            fontWeight: "600",
            color: theme.colorPrimary,
            textDecoration: "none",
            margin: "0 1rem"
        },
        "@media(max-width: 60rem)": {
            margin: "0.3rem 0"
        }
    },
    terms: {
        textAlign: "center",
        color: theme.colorInactive,
        textDecoration: "none",
        "@media(min-width: 60rem)": {
            position: "fixed",
            bottom: "0.2rem",
            left: "36rem"
        }
    }
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <nav className={classes.root}>
            <div className={classes.navMenu}>
                <a href="https://om.bompengekalkulator.no">Om</a>
                <a href="https://om.bompengekalkulator.no/blogg">Blogg</a>
                <a href="https://om.bompengekalkulator.no/apper">Apper</a>
                <a href="https://om.bompengekalkulator.no/integrasjon">
                    Integrasjon
                </a>
            </div>
            <a
                className={classes.terms}
                href="https://dib.no/personvern"
            >
                Personvernerklæring
            </a>
        </nav>
    );
};

export default Footer;
