import React, {createContext, useState} from "react";
import {createUseStyles} from "react-jss";
import PrintModal from "./PrintModal"; // eslint-disable-line
import Icon from "../common/Icon";
import PrintIcon from "../../assets/svg/print.svg";

export const PrintContext = createContext({});

const useStyles = createUseStyles({
    button: {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        cursor: "pointer"
    }
});

const Print = (props) => {
    const [visible, setVisible] = useState(false);
    const classes = useStyles();

    return (
        <PrintContext.Provider value={props}>
            <button
                className={classes.button}
                type="button"
                onClick={() => setVisible(!visible)}
            >
                <Icon svg={PrintIcon} size="LARGE"/>
            </button>
            {visible && <PrintModal onExit={setVisible} />}
        </PrintContext.Provider>
    );
};

export default Print;
