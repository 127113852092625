/* eslint-disable */
import React from "react";

const Car = ({height, width}) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 24 24" 
            height={height ? height : "48"} 
            width={width ? width : "48"}>
            <path
                d="M23,14.68c-0.138,0-0.25-0.112-0.25-0.25v-2.25c0-1.105-0.895-2-2-2h-0.477c-0.095,0-0.181-0.053-0.224-0.138l-1.625-3.259 c-0.34-0.678-1.035-1.105-1.794-1.1H8.62c-0.759-0.005-1.455,0.421-1.795,1.1L5.2,10.042c-0.043,0.085-0.129,0.138-0.224,0.138H2 c-1.105,0-2,0.895-2,2v2.5c0,0.729,0.525,1.353,1.244,1.477c0.136,0.024,0.266-0.067,0.289-0.203
                c0.001-0.004,0.001-0.008,0.002-0.012c0.203-1.638,1.694-2.801,3.332-2.599c1.366,0.169,2.44,1.249,2.601,2.617 c0.015,0.126,0.121,0.22,0.248,0.22h7.318c0.127,0.001,0.234-0.094,0.248-0.22c0.2-1.642,1.693-2.811,3.335-2.611 c1.502,0.183,2.631,1.458,2.633,2.971c0.002,0.03,0.002,0.061,0,0.091c-0.01,0.138,0.093,0.258,0.23,0.268
                c0.007,0.001,0.013,0.001,0.02,0.001H23c0.552,0,1-0.448,1-1C24,15.128,23.552,14.68,23,14.68z M11.75,9.43 c0,0.138-0.112,0.25-0.25,0.25H8.024c-0.138,0-0.25-0.111-0.25-0.25c0-0.039,0.009-0.078,0.026-0.112l0.751-1.5 C8.593,7.733,8.68,7.68,8.774,7.68H11.5c0.138-0.001,0.249,0.11,0.25,0.248c0,0.001,0,0.001,0,0.002V9.43z
                M17.442,9.562 C17.396,9.635,17.316,9.68,17.23,9.68H14c-0.138,0-0.25-0.112-0.25-0.25v-1.5c0-0.138,0.112-0.25,0.25-0.25h2.48 c0.095,0,0.182,0.053,0.224,0.138l0.75,1.5C17.492,9.396,17.488,9.488,17.442,9.562z M4.5,14.32c1.105,0,2,0.895,2,2s-0.895,2-2,2 s-2-0.895-2-2S3.395,14.32,4.5,14.32z
                M18.25,14.32c1.105,0,2,0.895,2,2s-0.895,2-2,2s-2-0.895-2-2S17.145,14.32,18.25,14.32z"
                stroke="none"
                fill={"currentColor"}
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round">
            </path>
        </svg>
    );
}

export default Car;