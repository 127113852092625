import React, {useEffect, useState} from "react";
import {createUseStyles} from "react-jss";
import {useSelector, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {submitForm, setFormData} from "../../redux/actions/formDataActions";
import {submitValidate} from "../../utils/utils";
import SearchDropdowns from "./SearchDropdowns";
import VehicleOptions from "./VehicleOptions";
import Submit from "../common/Submit";

const useStyles = createUseStyles({
    root: {
        padding: "0 1.2rem"
    },
    submit: {
        display: "flex",
        justifyContent: "center",
        margin: "1rem 0"
    }
});

const Form = ({match, history}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {formData, submittedFormData} = useSelector(state => ({
        formData: state.formData,
        submittedFormData: state.submittedFormData
    }));
    const [fromError, setFromError] = useState(false);
    const [toError, setToError] = useState(false);

    useEffect(() => {
        if (match.params.formData) {
            try {
                const formDataStringFromURL = atob(match.params.formData);
                if (JSON.stringify(formData) !== formDataStringFromURL) {
                    dispatch(setFormData(JSON.parse(formDataStringFromURL)));
                    setTimeout(() => {
                        dispatch(submitForm());
                    }, 1000);
                }
            } catch (error) {
                history.replace("");
            }
        }
    }, [match]);

    const handleSubmit = () => {
        if (submitValidate(formData, submittedFormData)) {
            if (formData.from === "" || formData.to === "") {
                if (formData.from === "") setFromError(true);
                if (formData.to === "") setToError(true);
            } else if (
                formData.from.Name === formData.to.Name &&
                formData.via.length === 0
            ) {
                setToError(true);
            } else {
                dispatch(submitForm(history));
            }
        }
    };

    return (
        <form className={classes.root}>
            <SearchDropdowns
                submit={handleSubmit}
                fromError={fromError}
                setFromError={setFromError}
                toError={toError}
                setToError={setToError}
            />
            <VehicleOptions submit={handleSubmit} />
            <div className={classes.submit}>
                <Submit
                    onClick={handleSubmit}
                    validate={() => submitValidate(formData, submittedFormData)}
                >
                    BEREGN
                </Submit>
            </div>
        </form>
    );
};

Form.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired
}

export default withRouter(Form);
