import {
    SET_RESULT,
    SET_RESULT_LOADING,
    SELECT_ROUTE
} from "../actions/resultActions";

export const resultReducer = (state = {}, {type, payload}) => {
    switch (type) {
        case SET_RESULT:
            return {
                ...state,
                ...payload
            }
        case SET_RESULT_LOADING:
            return {
                ...state,
                loading: payload
            }
        case SELECT_ROUTE:
            return {
                ...state,
                Tur: state.Tur.map((route, index) => {
                    return {
                        ...route,
                        Selected: payload === index
                    };
                })
            };
        default:
            return state;
    }
};

