import React from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    root: {
        padding: "0.8rem 0",
        color: theme.colorPrimary,
        fontFamily: "Caveat Brush",
        textAlign: "center",
        fontSize: props => props.fontSize ? props.fontSize : "2.2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none"
    },
    link: {
        textDecoration: "none"
    }
}));

const Header = props => {
    const {children, href} = props;
    const classes = useStyles(props);
    return (
        <a href={href} className={classes.link}>
            <div className={classes.root}>{children}</div>
        </a>
    );
};

Header.defaultProps = {
    children: null,
    href: ""
}

Header.propTypes = {
    href: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
};

export default Header;
