import React from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import {formatPrice} from "../../utils/utils";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontWeight: props => props.bold ? "bold" : "normal",
        color: props => props.color,
        margin: "0.5rem 0",
        padding: props => props.padding
    },
    span: {
        display: "flex",
        alignItems: "center"
    }
});

const RouteInfo = (props) => {
    const {from, to, distance, duration, price} = props;
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <span className={classes.span}>
                {`${from.Name.split(",")[0]} - ${to.Name.split(",")[0]}`}
            </span>
            {distance && <span>{distance}</span>}
            {duration && <span>{duration}</span>}
            {price && <span>{formatPrice(price)}</span>}
        </div>
    );
};

RouteInfo.defaultProps = {
    from: "",
    to: "",
    distance: "",
    duration: "",
    price: ""
}

RouteInfo.propTypes = {
    from: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    to: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])),
    distance: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    duration: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    price: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default RouteInfo;
