import {useState, useEffect} from "react";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {initMonthlyCount} from "../redux/actions/monthlyLimitActions";

export const useSearch = (search, id) => {
    const [searchText, setSearchText] = useState("");
    const [debouncer, setDebouncer] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);

    const shouldSearch = () => !!searchText;

    const doSearch = () => {
        if (searchText.length >= 3) {
            search(searchText.trim(), id);
            setHasSearched(true);
        } else {
            setDebouncer(null);
        }
    };

    const initializeSearch = () => {
        if (!hasSearched) {
            setDebouncer(setTimeout(doSearch, 500));
        } else {
            setDebouncer(setTimeout(doSearch, 500));
        }
    };

    useEffect(() => {
        clearTimeout(debouncer);

        if (shouldSearch()) {
            initializeSearch();
        }

        return () => {
            clearTimeout(debouncer);
        };
    }, [searchText]);

    return [searchText, setSearchText];
};

export const useAutoCompleteCookies = (autoComplete, id) => {
    const [cookies, setCookie] = useCookies(["autocomplete"]);
    const inputStates = useSelector(state => [
        state.formData.from,
        state.formData.to,
        ...state.formData.via
    ]);
    let autoCompleteData = [...autoComplete];

    if (!cookies.autocomplete) {
        setCookie("autocomplete", [], {path: "/"});
    }

    const elements =
        cookies.autocomplete &&
        cookies.autocomplete.filter(elem => {
            return inputStates.some(inputElem => {
                return id !== inputElem.inputId && elem.Id === inputElem.Id;
            });
        });

    if (
        cookies.autocomplete &&
        autoCompleteData.length === 0 &&
        cookies.autocomplete.length > 0
    ) {
        if (elements && elements.length > 0) {
            autoCompleteData = cookies.autocomplete.filter(
                el => !elements.includes(el)
            );
        } else {
            autoCompleteData.push(...cookies.autocomplete);
        }
    }

    const addCookie = val => {
        let index = -1;

        let newArr = [];
        if (cookies.autocomplete.length > 0) {
            index = cookies.autocomplete.findIndex(el => {
                return el.Id === val.Id;
            });
            newArr = [...cookies.autocomplete];
        }
        if (index !== -1) {
            newArr[index] = {
                ...val,
                times: newArr[index].times ? newArr[index].times + 1 : 1
            };
        } else {
            newArr.push({...val, times: 1});
        }
        setCookie(
            "autocomplete",
            newArr.sort((x, y) => y.times - x.times),
            {
                path: "/"
            }
        );
    };

    return [autoCompleteData, addCookie];
};

export const useMonthlyLimit = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initMonthlyCount());
    }, []);

    return useSelector(state => state.monthlyLimit.limitReached);
};

export const useOverloaded = () => {
    return useSelector(state => state.overloaded.overloaded);
};
