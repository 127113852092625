import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import SearchDropdown from "./SearchDropdown";


const useStyles = createUseStyles(theme => ({
    addViaRow: {
        display: "flex",
        justifyContent: "flex-end"
    },
    addViaButton: {
        margin: "-0.3rem 0.4rem",
        padding: "0",
        cursor: "pointer",
        color: theme.colorPrimary,
        background: "none",
        border: "none",
        outline: "none"
    }
}));

const ViaSearchDropdowns = props => {
    const {
        autoComplete,
        getAutoComplete,
        resetAutoComplete,
        onAddVia,
        onRemoveVia
    } = props;

    const via = useSelector(state => state.formData.via);

    const [viaDropdowns, setViaDropdowns] = useState([]);
    const classes = useStyles();
    const focusVia = useRef(null);

    useEffect(() => {
        if (via.length > 0) {
            const newVia = via.map((v, i) => ({id: i , key: `via${i }`, visible: true, value: v.Name}));
            setViaDropdowns(newVia)
        }
    }, [via])

    useEffect(() => {
        if (viaDropdowns.length > 0 && focusVia.current !== null) {
            focusVia.current.focus();
        }
    }, [viaDropdowns]);

    const addViaDropdown = () => {
        setViaDropdowns(
            viaDropdowns.concat({
                id: viaDropdowns.length,
                key: `via${viaDropdowns.length}`,
                visible: true
            })
        );
    };

    const handleRemoveVia = (e, id) => {
        setViaDropdowns(
            viaDropdowns.map(el => ({
                ...el,
                visible: el.visible && el.id !== id - 2
            }))
        );
        onRemoveVia(id);
    };

    const viaDropdownList = viaDropdowns
        .filter(el => el.visible)
        .map(el => (
            <span key={el.key}>
                <SearchDropdown
                    value={el.value}
                    autoComplete={autoComplete[el.id + 2]}
                    placeholder={el.value ? undefined : "Via"}
                    getAutoComplete={getAutoComplete}
                    resetAutoComplete={resetAutoComplete}
                    id={el.id + 2}
                    iconOnClick={handleRemoveVia}
                    setValue={onAddVia}
                    setRef={focusVia}
                />
            </span>
        ));
    
    return (
        <div>
            {viaDropdownList}
            {viaDropdownList.length < 3 && (
                <span className={classes.addViaRow}>
                    <button
                        type="button"
                        className={classes.addViaButton}
                        onClick={addViaDropdown}
                        tabIndex="-1"
                    >
                        + Via
                    </button>
                </span>
            )}
        </div>
    );
};

ViaSearchDropdowns.propTypes = {
    autoComplete: PropTypes.objectOf(
        PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ).isRequired,
    getAutoComplete: PropTypes.func.isRequired,
    resetAutoComplete: PropTypes.func.isRequired,
    onAddVia: PropTypes.func.isRequired,
    onRemoveVia: PropTypes.func.isRequired
};

export default ViaSearchDropdowns;
