import {
    SET_AUTOPASS,
    SET_RUSHTID
} from "../actions/resultOptionsActions";

export const resultOptionsReducer = (
    state = {
        autopass: true,
        rushtid: false
    },
    {type, payload}
) => {
    switch (type) {
        case SET_AUTOPASS:
            return {
                ...state,
                autopass: payload
            };
        case SET_RUSHTID:
            return {
                ...state,
                rushtid: payload
            };
        default: 
            return state;
    }
}