import React, {useState} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    root: {
        display: props => (props.inline ? "inline-flex" : "block"),
        color: props => props.color,
        padding: "1px",
        width: "auto",
        cursor: "pointer",
        alignItems: "center",
        fontSize: "1rem",
        "&:focus": {
            outline: "none"
        }
    },
    body: {
        width: props => (props.size ? `${props.size * 1.8}rem` : "1.6rem"),
        height: props => (props.size ? `${props.size / 2}rem` : "0.5rem"),
        margin: props =>
            props.inline
                ? props.size
                    ? `${props.size / 2}rem`
                    : "0.5rem"
                : props.size
                    ? `${props.size / 2}rem auto`
                    : "0.5rem auto",
        borderRadius: "1rem",
        transition: "all 0.1s ease"
    },
    body_on: {
        backgroundColor: "rgba(4, 162, 176, 0.5)"
    },
    body_off: {
        backgroundColor: "#e6e6e6"
    },
    toggle: {
        position: "relative",
        width: props => (props.size ? `${props.size}rem` : "1rem"),
        height: props => (props.size ? `${props.size}rem` : "1rem"),
        borderRadius: "1rem",
        transition: "all 0.1s ease",
        top: "-50%"
    },
    toggle_on: {
        backgroundColor: theme.colorPrimary,
        left: "50%"
    },
    toggle_off: {
        backgroundColor: "#d9d7d7",
        left: "0%"
    },
    label_on: {
        userSelect: "none",
        color: theme.colorPrimary,
        transition: "all 0.15s ease"
    },
    label_off: {
        userSelect: "none",
        color: "grey",
        transition: "all 0.15s ease"
    },
    focus: {
        color: theme.colorPrimary
    }
}));

const Toggle = props => {
    const {label, onToggle, submit, toggled} = props;
    const classes = useStyles(props);
    const [isFocused, setFocus] = useState(false);

    const handleClick = () => {
        onToggle(!toggled);
    };

    const handleKeyDown = e => {
        if (e.key === "ArrowRight") {
            onToggle(true);
        } else if (e.key === "ArrowLeft") {
            onToggle(false);
        } else if (e.key === "Space") {
            onToggle(!toggled);
        } else if (e.key === "Enter") {
            if (submit) submit();
        }
    };

    return (
        <div
            className={classes.root}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex="0" // eslint-disable-line
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            role="none"
        >
            <div
                className={`${
                    toggled ? classes.label_on : classes.label_off
                } ${isFocused && classes.focus}`}
            >
                {label}
            </div>
            <div
                className={`${classes.body} ${
                    toggled ? classes.body_on : classes.body_off
                }`}
            >
                <div
                    className={`${classes.toggle} ${
                        toggled ? classes.toggle_on : classes.toggle_off
                    }`}
                />
            </div>
        </div>
    );
};

Toggle.defaultProps = {
    label: "",
    submit: null,
    toggled: false,
    onToggle: null
};

Toggle.propTypes = {
    onToggle: PropTypes.func,
    label: PropTypes.string,
    submit: PropTypes.func,
    toggled: PropTypes.bool
};

export default Toggle;
