/* eslint-disable */
import React from "react";

const Car = ({height, width}) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 24 24" 
            height={height ? height : "48"} 
            width={width ? width : "48"}>
            <path
                d="M22.566,4H11.934c-0.81,0.02-1.451,0.69-1.434,1.5v9.777c0,0.085,0.043,0.164,0.115,0.21 c0.791,0.503,1.3,1.348,1.376,2.282c0.01,0.13,0.119,0.23,0.249,0.231h3.52c0.131,0,0.239-0.101,0.249-0.231 c0.128-1.652,1.571-2.887,3.223-2.759c1.474,0.114,2.644,1.285,2.759,2.759c0.01,0.13,0.119,0.23,0.249,0.231h1.26
                c0.276,0,0.5-0.224,0.5-0.5v-12C24.017,4.69,23.376,4.02,22.566,4z M0,16.5c0,1.105,0.895,2,2,2h3.763 c0.139,0,0.251-0.113,0.25-0.252c0-0.008,0-0.015-0.001-0.023C6.005,18.151,6,18.076,6,18c0-1.567,1.206-2.87,2.769-2.991 c0.13-0.01,0.23-0.119,0.231-0.249V7.5c0-0.552-0.448-1-1-1H5c-2.76,0.003-4.997,2.24-5,5V16.5z
                M5,8.5h1.75 C6.888,8.5,7,8.612,7,8.75v2.5c0,0.138-0.112,0.25-0.25,0.25H2.261c-0.138,0-0.25-0.112-0.25-0.25c0-0.008,0-0.015,0.001-0.023 C2.153,9.682,3.449,8.5,5,8.5z M9,16c1.105,0,2,0.895,2,2s-0.895,2-2,2s-2-0.895-2-2S7.895,16,9,16z M19,16c1.105,0,2,0.895,2,2
                s-0.895,2-2,2s-2-0.895-2-2S17.895,16,19,16z"
                stroke="none"
                fill={"currentColor"}
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round">
            </path>
        </svg>
    );
}

export default Car;