import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import withStyles, {useTheme} from "react-jss";
import Select, {components} from "react-select";
import {useSearch, useAutoCompleteCookies} from "../../utils/hooks";
import ClearIcon from "../../assets/svg/Clear";

const styles = theme => ({
    root: {
        margin: "0.8rem auto",
        position: "relative"
    },
    placeholder: {
        transition: "all 150ms ease",
        position: "absolute",
        color: theme.colorText,
        backgroundColor: theme.backgroundPrimary,
        top: "1.1rem",
        left: "0.4rem",
        lineHeight: "1rem",
        padding: "0rem 0.4rem",
        fontSize: "1.2rem",
        pointerEvents: "none",
        userSelect: "none"
    },
    placeholderSelected: {
        top: "-0.6rem",
        fontSize: "1.1rem"
    },
    placeholderFocused: {
        top: "-0.6rem",
        fontSize: "1.1rem",
        color: theme.colorPrimary
    },
    placeholderWarning: {
        top: "-0.6rem",
        fontSize: "1.1rem",
        color: theme.colorWarning
    },
    button: {
        position: "absolute",
        right: "0.7rem",
        top: "0.7rem",
        padding: "0",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        outline: "none"
    }
});

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.selectProps.error
            ? `0 0 0 1px ${state.theme.colors.danger}`
            : provided.boxShadow,
        borderColor: state.selectProps.error
            ? state.theme.colors.danger
            : provided.borderColor,
        fontSize: "1.2rem",
        cursor: "text",
        maxWidth: "100%"
    }),
    menu: (provided, state) => ({
        ...provided,
        display: state.options.length === 0 ? "none" : "block",
        marginTop: "1px"

    }),
    option: (provided, state) => ({
        ...provided,
        color: state.theme.colors.neutral20,
        cursor: "pointer",
        backgroundColor: state.isSelected
            ? state.theme.colors.primary50
            : state.isFocused
                ? state.theme.colors.primary25
                : provided.backgroundColor,
        "&:hover": {
            backgroundColor: state.isSelected ? "" : provided.backgroundColor
        }
    }),
    indicatorsContainer: () => ({
        display: "none"
    }),
    noOptionsMessage: () => ({
        display: "none"
    }),
    singleValue: () => ({
        display: "none"
    }),
    input: provided => ({
        ...provided,
        width: "25rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "& div": {
            width: "100%"
        }
    })
};

const Input = props => {
    return <components.Input {...props} isHidden={false} />;
};

const SearchDropdown = props => {
    const {
        autoComplete,
        classes,
        error,
        setError,
        getAutoComplete,
        id,
        iconOnClick,
        value,
        setValue,
        setRef,
        resetAutoComplete,
        placeholder
    } = props;

    const [searchText, setSearchText] = useSearch(getAutoComplete, id);
    const [autoCompleteData, addCookie] = useAutoCompleteCookies(
        autoComplete,
        id
    );
    const [isFocused, setIsFocused] = useState(false);
    const [hasContent, setHasContent] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const selectRef = useRef(0);
    const theme = useTheme();

    useEffect(() => {
        setInputValue(value);
        if (value.length > 0) {
            setHasContent(true);
        } else {
            setHasContent(false);
        }
    }, [value]);

    useEffect(() => {
        if (searchText.length < 1) {
            resetAutoComplete(id);
            setHasContent(false);
        } else {
            setHasContent(true);
        }
    }, [searchText]);

    const handleChange = val => {
        setInputValue(val.label);
        setIsSelected(true);
        setValue({Name: val.label, Id: val.value, inputId: id});
        addCookie({Name: val.label, Id: val.value});
    };
    const handleFocus = () => {
        if (setError) setError(false);
        setIsFocused(true);
    };
    const handleBlur = () => {
        if (setError) setError(false);
        setIsFocused(false);
    };

    const handleInputChange = (inputVal, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setSearchText(inputVal);
            setInputValue(inputVal);
        }
    };

    const handleKeyDown = evt => { // eslint-disable-line
        switch (evt.key) {
            case "Home":
                evt.preventDefault();
                if (evt.shiftKey) evt.target.selectionStart = 0; // eslint-disable-line
                else evt.target.setSelectionRange(0, 0);
                break;
            case "End":
                evt.preventDefault();
                const len = evt.target.value.length; // eslint-disable-line
                if (evt.shiftKey) evt.target.selectionEnd = len; // eslint-disable-line
                else evt.target.setSelectionRange(len, len);
                break;
            default: break
        }
    };

    const options = autoCompleteData
        .map(el => ({
            value: el.Id,
            label: el.Name
        }))
        .slice(0, 5);

    return (
        <div className={classes.root} ref={setRef}>
            <Select
                classes={classes}
                components={{Input}}
                ref={selectRef}
                onInputChange={handleInputChange}
                onChange={handleChange}
                inputValue={inputValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                options={options}
                isSearchable
                iconOnClick={iconOnClick}
                id={id}
                placeholder=""
                styles={selectStyles}
                error={error}
                theme={selectTheme => ({
                    ...selectTheme,
                    borderRadius: theme.borderRadius,
                    colors: {
                        ...selectTheme.colors,
                        primary: theme.colorPrimary,
                        primary25: "rgba(4, 162, 176,0.1)",
                        primary50: "rgba(4, 162, 176,0.2)",
                        neutral20: theme.colorText,
                        neutral30: theme.colorText,
                        danger: theme.colorWarning
                    }
                })}
            />
            <div
                className={`
                ${classes.placeholder} 
                ${isFocused && classes.placeholderFocused} 
                ${(isSelected || hasContent) && classes.placeholderSelected}
                ${error && classes.placeholderWarning}
            `}
            >
                {placeholder}
            </div>
            {iconOnClick && (
                <button
                    type="button"
                    className={classes.button}
                    onClick={e => iconOnClick(e, id)}
                >
                    <ClearIcon
                        height="1.8rem"
                        width="1.8rem"
                        fill={isFocused ? theme.colorPrimary : "grey"}
                    />
                </button>
            )}
        </div>
    );
};

SearchDropdown.defaultProps = {
    autoComplete: [],
    error: false,
    iconOnClick: null,
    placeholder: "",
    setError: null,
    setRef: null,
    value: ""
};

SearchDropdown.propTypes = {
    autoComplete: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    error: PropTypes.bool,
    getAutoComplete: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    iconOnClick: PropTypes.func,
    placeholder: PropTypes.string,
    resetAutoComplete: PropTypes.func.isRequired,
    value: PropTypes.string,
    setError: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    setRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.instanceOf(Element)})
    ])
};

export default withStyles(styles)(SearchDropdown);
