import React from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import classnames from "classnames";
import {sizes, iconSizes} from "../../config/constants";

const useStyles = createUseStyles({
    svg: {
        color: color => color,
        display: "block"
    },
    [sizes.SMALL]: {
        height: iconSizes[sizes.SMALL],
        width: iconSizes[sizes.SMALL]
    },
    [sizes.MEDIUM]: {
        height: iconSizes[sizes.MEDIUM],
        width: iconSizes[sizes.MEDIUM]
    },
    [sizes.LARGE]: {
        height: iconSizes[sizes.LARGE],
        width: iconSizes[sizes.LARGE]
    },
    [sizes.HUGE]: {
        height: iconSizes[sizes.HUGE],
        width: iconSizes[sizes.HUGE]
    }
});

const Icon = props => {
    const {className, color, size, svg: svgObject, title, iconRef, style} = props;
    const classes = useStyles(color);

    const svgClasses = classnames(
        classes.svg,
        {
            [classes[size]]: size !== sizes.IGNORE
        },
        className
    );

    return (
        <svg
            className={svgClasses}
            title={title}
            viewBox={svgObject.viewBox}
            style={style}
            ref={iconRef}
        >
            {title && <title>{title}</title>}
            <use xlinkHref={`#${svgObject.id}`} />
        </svg>
    );
};

Icon.defaultProps = {
    className: "",
    color: "inherit",
    size: sizes.MEDIUM,
    title: "",
    style: null,
    iconRef: null
};

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOf(Object.getOwnPropertyNames(sizes)),
    svg: PropTypes.shape({
        id: PropTypes.string.isRequired,
        viewBox: PropTypes.string.isRequired
    }).isRequired,
    title: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    iconRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ])
};

export default Icon;
