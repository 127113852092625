export const SET_AUTOPASS = "SET_AUTOPASS";
export const SET_RUSHTID = "SET_RUSHTID";

export const setAutopass = payload => {
    return {
        type: SET_AUTOPASS,
        payload
    };
};

export const setRushtid = payload => {
    return {
        type: SET_RUSHTID,
        payload
    };
};