import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {createUseStyles, useTheme} from "react-jss";
import RouteInfo from "./RouteInfo";
import ChargePoint from "./ChargePoint";
import {findToll} from "../../utils/utils";

const useStyles = createUseStyles(theme => ({
    divider: {
        margin: "1rem",
        height: "1px",
        borderTop: "1px solid",
        color: theme.colorPrimary
    },
    noStations: {
        margin: "0 1rem",
        fontSize: "1.1rem",
        color: "grey"
    }
}));

const RouteDetails = props => {
    const {
        tur,
        retur,
        tripSum,
        roundtripSum
    } = props;

    const classes = useStyles();
    const theme = useTheme();

    const {
        autopass,
        rushtid,
        submittedFormData
    } = useSelector(state => ({
        autopass: state.autopass,
        rushtid: state.rushtid,
        submittedFormData: state.submittedFormData
    }));

    const chargePoints = tur.AvgiftsPunkter.filter(station =>
        findToll(station, submittedFormData, rushtid)
    ).map(station => (
        <ChargePoint
            key={`${station.Latitude}_${station.Longitude}`}
            station={station}
            rushtid={rushtid}
            autopass={autopass}
        />
    ));

    let roundtripChargePoints;
    if (retur) {
        roundtripChargePoints = retur.AvgiftsPunkter.map(station => (
            <ChargePoint
                key={`${station.Latitude}_${station.Longitude}`}
                rushtid={rushtid}
                station={station}
                autopass={autopass}
            />
        ));
    }

    return (
        <div>
            {retur && (
                <RouteInfo
                    from={submittedFormData.from}
                    to={submittedFormData.to}
                    duration={tur.TidNice}
                    price={tripSum}
                    color={theme.colorPrimary}
                    padding="0 1rem"
                />
            )}
            {chargePoints.length > 0 ? (
                chargePoints
            ) : (
                <div className={classes.noStations}>Ingen bomstasjoner</div>
            )}
            {retur && (
                <Fragment>
                    <div className={classes.divider} />
                    <RouteInfo
                        from={submittedFormData.to}
                        to={submittedFormData.from}
                        duration={tur.TidNice}
                        price={roundtripSum}
                        color={theme.colorPrimary}
                        padding="0 1rem"
                    />
                </Fragment>
            )}
            {roundtripChargePoints}
        </div>
    );
};

RouteDetails.defaultProps = {
    retur: null,
    tripSum: "",
    roundtripSum: ""
}

RouteDetails.propTypes = {
    tur: PropTypes.objectOf(PropTypes.any).isRequired,
    retur: PropTypes.objectOf(PropTypes.any),
    tripSum: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    roundtripSum: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default RouteDetails;
