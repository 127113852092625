import Cookies from "universal-cookie";

export const vehicleOptionsCookie = () => {
    const cookies = new Cookies();

    const getWeightClass = () => {
        const vehicleoptions = cookies.get("vehicleoptions");
        if (vehicleoptions && vehicleoptions.vehiclesize) {
            return vehicleoptions.vehiclesize;
        }
        return null;
    };

    const setWeightClassCookie = size => {
        const vehicleoptions = cookies.get("vehicleoptions");
        cookies.set(
            "vehicleoptions",
            {...vehicleoptions, vehiclesize: size},
            {path: "/"}
        );
    };

    const getSmallVehicleType = () => {
        const vehicleoptions = cookies.get("vehicleoptions");
        if (vehicleoptions && vehicleoptions.smallvehicletype) {
            return vehicleoptions.smallvehicletype;
        }
        return null;
    };

    const setSmallVehicleTypeCookie = type => {
        const vehicleoptions = cookies.get("vehicleoptions");
        cookies.set(
            "vehicleoptions",
            {...vehicleoptions, smallvehicletype: type},
            {path: "/"}
        );
    };

    const getBigVehicleType = () => {
        const vehicleoptions = cookies.get("vehicleoptions");
        if (vehicleoptions && vehicleoptions.bigvehicletype) {
            return vehicleoptions.bigvehicletype;
        }
        return null;
    };

    const setBigVehicleTypeCookie = type => {
        const vehicleoptions = cookies.get("vehicleoptions");
        cookies.set(
            "vehicleoptions",
            {...vehicleoptions, bigvehicletype: type},
            {path: "/"}
        );
    };

    return {
        getWeightClass,
        setWeightClassCookie,
        getSmallVehicleType,
        setSmallVehicleTypeCookie,
        getBigVehicleType,
        setBigVehicleTypeCookie
    };
};

export const getMonthlyCountCookie = () => {
    const cookies = new Cookies();
    const c = cookies.get("count");
    const m = cookies.get("month");
    const count = c ? parseInt(c) : 1; // eslint-disable-line
    const month = m ? parseInt(m) : -1; // eslint-disable-line

    return [count, month];
};

export const setMonthlyCountCookie = count => {
    const cookies = new Cookies();
    const date = new Date();
    const month = date.getMonth();
    cookies.set("count", count, {path: "/"});
    cookies.set("month", month, {path: "/"});
};
