export default {
    "default":{pos: {lat: 61, lng: 9}, zoom: 7},
    "nord":{pos: {lat: 69.27, lng: 20.26}, zoom: 7},
    "oslo": {pos: {lat: 59.90, lng: 10.76}, zoom: 12},
    "kristiansand": {pos: {lat: 58.16, lng: 8.01}, zoom: 14},
    "porsgrunn": {pos: {lat: 59.14, lng: 9.65}, zoom: 12},
    "stavanger": {pos: {lat: 58.93, lng: 5.74}, zoom: 12},
    "haugesund": {pos: {lat: 59.41, lng: 5.27}, zoom: 13},
    "bergen": {pos: {lat: 60.39, lng: 5.32}, zoom: 12},
    "trondheim": {pos: {lat: 63.41, lng: 10.43}, zoom: 12},
    "bodø": {pos: {lat: 67.28, lng: 14.41}, zoom: 13},
    "harstad": {pos: {lat: 68.79, lng: 16.54}, zoom: 13},
    "førde": {pos: {lat: 61.45, lng: 5.85}, zoom: 13}
}