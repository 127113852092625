import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {Router, Route, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";
import {CookiesProvider} from "react-cookie";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";
import App from "./App";

require("isomorphic-fetch");

const store = configureStore();
const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <CookiesProvider>
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={App}/>
                    <Route path="/rute/:formData" component={App}/>
                    <Route path="/:location/" component={App}/>
                </Switch>
            </Router>
        </CookiesProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
