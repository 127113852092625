import {
    SET_OVERLOADED
} from "../actions/overloadedActions";

const initState = {
    overloaded: false
}

export const overloadedReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SET_OVERLOADED:
            return {
                overloaded: payload.value
            };
        default:
            return state;
    }
};