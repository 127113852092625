import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import ExpandIcon from "../../assets/svg/expand.svg";
import Icon from "./Icon";

const useStyles = createUseStyles(theme => ({
    selected: {
        borderLeft: `6px solid ${theme.colorPrimary}`
    },
    notSelected: {
        borderLeft: "6px solid rgb(255,255,255)"
    },
    summary: {
        backgroundColor: "white",
        border: "none",
        cursor: "pointer",
        display: "flex",
        fontFamily: "inherit",
        justifyContent: "space-between",
        alignItems: "center",
        transition: "height 300ms ease",
        outline: "none",
        padding: "0",
        width: "100%",
        appearance: "none",
        overflow: "hidden"
    },
    details: {
        padding: "0 1rem",
        overflow: "hidden"
    },
    expanded: {
        padding: "0 1rem",
        borderLeft: props =>
            props.borderLeft === "small" && `2px solid ${theme.colorPrimary}`
    },
    iconContainer: {
        float: "left",
        display: "flex",
        justifyContent: "center",
        width: "12%",
        flexShrink: 0
    },
    icon: {
        userSelect: "none"
    },
    iconExpanded: {
        transform: props =>
            props.iconDir === "left" ? "rotate(-180deg)" : "rotate(180deg)",
        transition: "transform 300ms ease"
    }
}));

const ExpansionPanel = props => {
    const {
        children,
        expanded,
        id,
        icon,
        iconDir,
        summary,
        setSelected,
        selected,
        animationSpeed,
        getExpanded
    } = props;

    const [isExpanded, setIsExpanded] = useState(expanded);
    const isInitialMount = useRef(true);
    const detailsElem = useRef(0);
    const iconRef = useRef(0);
    const classes = useStyles(props);

    useEffect(() => {
        if (getExpanded) getExpanded(isExpanded);
    }, [isExpanded]);

    const collapsePanel = () => {
        const {transition} = detailsElem.current.parentNode.style;
        detailsElem.current.parentNode.style.transition = "";
        requestAnimationFrame(() => {
            detailsElem.current.parentNode.style.height = `${detailsElem.current.offsetHeight}px`;
            detailsElem.current.parentNode.style.transition = transition;
            requestAnimationFrame(() => {
                setIsExpanded(false);
            });
        });
    };

    const expandPanel = () => {
        if (iconRef.current) {
            iconRef.current.style.transform =
                iconDir === "left" ? "rotate(-180deg)" : "rotate(180deg)";
        }
        detailsElem.current.parentNode.style.height = `${detailsElem.current.offsetHeight}px`;
        const expand = () => {
            detailsElem.current.parentNode.removeEventListener(
                "transitionend",
                expand
            );
            detailsElem.current.parentNode.style.height = "auto";
            setIsExpanded(true);
        };
        detailsElem.current.parentNode.addEventListener(
            "transitionend",
            expand
        );
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (selected && !isExpanded) {
            expandPanel();
        } else if (!selected && isExpanded) {
            collapsePanel();
        }
    }, [selected]);

    const handleClick = () => {
        if (setSelected && !selected) {
            setSelected(id);
        }
        if (isExpanded) {
            collapsePanel();
        } else {
            expandPanel();
        }
    };

    return (
        <div className={selected ? classes.selected : classes.notSelected}>
            <button
                className={classes.summary}
                onClick={handleClick}
                type="button"
            >
                {icon && iconDir === "left" && (
                    <div className={classes.iconContainer}>
                        <Icon
                            className={classes.icon}
                            style={{
                                transition: "transform 300ms ease",
                                transform: isExpanded
                                    ? iconDir === "left"
                                        ? "rotate(-180deg)"
                                        : "rotate(180deg)"
                                    : "none"
                            }}
                            size="LARGE"
                            svg={icon ? ExpandIcon : icon}
                            iconRef={iconRef}
                        />
                    </div>
                )}
                {summary}
                {icon && iconDir === "right" && (
                    <div className={classes.iconContainer}>
                        <Icon
                            className={classes.icon}
                            style={{
                                transition: "transform 300ms ease",
                                transform: isExpanded
                                    ? iconDir === "left"
                                        ? "rotate(-180deg)"
                                        : "rotate(180deg)"
                                    : "none"
                            }}
                            size="LARGE"
                            svg={icon ? ExpandIcon : icon}
                            iconRef={iconRef}
                        />
                    </div>
                )}
            </button>
            <div
                className={`${classes.details} ${isExpanded &&
                    classes.expanded}`}
                style={{
                    transition: `height ${animationSpeed}ms ease`,
                    height: isExpanded ? "auto" : "0px"
                }}
            >
                <div ref={detailsElem}>{children}</div>
            </div>
        </div>
    );
};

ExpansionPanel.defaultProps = {
    id: null,
    expanded: false,
    icon: null,
    iconDir: "right",
    animationSpeed: 300,
    setSelected: () => {},
    selected: null,
    getExpanded: () => {}
};

ExpansionPanel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    expanded: PropTypes.bool,
    id: PropTypes.number,
    icon: PropTypes.bool,
    iconDir: PropTypes.string,
    summary: PropTypes.element.isRequired,
    setSelected: PropTypes.func,
    selected: PropTypes.bool,
    animationSpeed: PropTypes.number,
    getExpanded: PropTypes.func
};

export default ExpansionPanel;
