export const SET_GEOLOCATION = "SET_GEOLOCATION";

export const setGeolocation = location => {
    return {
        type: SET_GEOLOCATION,
        location
    };
};

export const getGeolocation = () => {
    return dispatch => {
        navigator.geolocation.getCurrentPosition(position => {
            dispatch(
                setGeolocation({
                    pos: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    zoom: 12
                })
            );
        });
    };
};
